.Profile {
  width: 100%;
  display: flex;
  background: #F3F6FB;

  .newContainer {
    flex: 6;

    .top {
      color: #060606;
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .bottom {
      border-radius: 20px;
    }

    .top,
    .bottom {
      padding: 10px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      h1 {
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: center;

        .image-container{
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 7%;
          object-fit: contain;
          margin-right: 10px;
          margin-top: 10px;
          border: 0.4px solid #1B40C4; /* Use border property for stroke */
          z-index: 1;
          cursor: pointer;
        }

        .image-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        button {
          padding: 10px;
          border: none;
          border-radius: 7px;
          background: #1B40C4;
          color: rgba(255, 255, 255, 0.63);
          font-size: 20.654px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.207px;
          cursor: pointer;
          margin-top: 10px;
          font-size: 14px;
          margin-left: 10px;
          width: 140px;
          height: 41px;
          padding: 10px 10px 10px 20px;
          gap: 10px;
          flex-shrink: 0;
          margin-bottom: 10px;
          z-index: 1;
        }

        .info {
          color: #8A8A8A;
          font-size: 14.871px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.149px;
          margin-left: 10px;
          text-align: left;
        }        

      }

      .right {
        flex: 2;
        margin-top: 20px;
        margin-bottom: 20px;

        .top1 {
          color: #060606;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.294px;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .top2 {
          color: #060606;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.294px;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        form {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-right: 50px;
              color: #4B4B4B;
              font-size: 20.654px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.207px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              display: flex;
              width: 387px;
              height: var(--32, 32px);
              padding: var(--12, 12px) var(--16, 16px);
              align-items: center;
              gap: var(--8, 8px);
              border-radius: var(--8, 8px);
              border: 1px solid var(--Neutral-2, #D7D7DB);
              background: var(--white-100, #FFF);
              color: var(--Body-text, #4F5672);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              z-index: 1;
            }
          }

          button {
            width: 30%;
            padding: 10px;
            border: none;
            background-color: #1B40C4;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
            font-size: 16px;
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.63);
            font-size: 20.654px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.207px;
            z-index: 1;

            &:disabled{
              background-color: rgba(27, 64, 196, 0.322);
              cursor: not-allowed;
            }
          }

          .payment-options {
            border-radius: var(--16, 16px);
            background: var(--white-100, #FFF);
            box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
            margin-bottom: 20px;
            overflow: hidden;
          }
      
          .payment-options1 {
            border-radius: var(--16, 16px);
            background: var(--white-100, #FFF);
            box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
            margin-bottom: 20px;
            overflow: hidden;
            padding-top: 10px;
            padding-bottom: 10px;
          }
      
          .best-value {
            display: inline-flex;
            padding-left: 20px;
            justify-content: flex-end;
            align-items: center;
            gap: 96.21px;
            background: #E1E9FC;
            margin-top: 20px;
            position: relative; /* Required for absolute positioning of ::after pseudo-element */
          }
          
          .best-value::after {
            content: "";
            width: 25px;
            height: 25px;
            transform: rotate(-45deg);
            background: var(--white-100, #FFF);
            position: absolute;
            top: 0;
            right: -10px; /* Adjust the value as needed */
          }    
      
          .best-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #336AEA;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px; /* 192.308% */
            padding-right: 40px;
          }
      
          .row-payment {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-right: 20px;
            margin-left: 10px;
          }
      
          .subscribe-button {
            display: flex;
            width: 149.19px;
            height: 44px;
            padding: 12px 19.631px 13px 20px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 5px;
            background: #336AEA;
            margin-bottom: 20px;
            border: none;
            cursor: pointer;
          }
      
          .payment-text {
            padding: 10px 40px 15px 0px;
          }
      
          .subscribe-text {
            color: var(--white-100, #FFF);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 44px; /* 293.333% */
          }
      
          .year-text {
            color: #121212;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 160% */
            text-transform: uppercase;
            margin-left: 10px;
            margin-top: 10px;
          }
      
          .money-row{
            display: flex;
            flex-direction: row;
            margin-left: 10px;
            margin-top: 10px;
          }
      
          .price {
            color: #121212;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
          }
      
          .month-text {
            color: #424242;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            margin-top: 3px;
            margin-left: 3px;
          }
      
          .terms-text1 {
            color: #777;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            margin-left: 10px;
            margin-top: 10px;
          }
      
          .terms-text2 {
            color: #777;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            margin-left: 10px;
            margin-bottom: 10px;
          }
      
          .custom-color {
            color: #121212;
            margin-left: 0px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; /* 150% */
          }

          .custom-color1 {
            color: #121212;
            margin-left: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; /* 150% */
          }

        }
      }
    }
  }
}


@media only screen and (max-width: 768px) {

  .Profile {
    width: 100%;
    display: flex;
    background: #fff;
    height: auto;

    .sidebar {
      display: none; /* Hide the sidebar */
    }
  
    .newContainer {
      flex: 6;
  
      .top {
        color: #060606;
        font-size: 20.654px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.207px;
      }
  
      .bottom {
        border-radius: 20px;
      }
  
      .top,
      .bottom {
        padding: 10px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
  
        h1 {
          font-size: 20px;
        }
  
        .left {
          flex: 1;
          text-align: center;
  
          .image-container{
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
          }
  
          img {
            width: 80px;
            height: 80px;
            border-radius: 7%;
            object-fit: contain;
            margin-right: 10px;
            margin-top: 10px;
            border: 0.4px solid #1B40C4; /* Use border property for stroke */
          }
  
          .image-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
  
          button {
            padding: 10px;
            border: none;
            border-radius: 7px;
            background: #1B40C4;
            color: rgba(255, 255, 255, 0.63);
            font-size: 12.654px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.207px;
            cursor: pointer;
            margin-top: 10px;
            margin-left: 10px;
            width: 100px;
            height: auto;
            padding: 10px 10px 10px 20px;
            gap: 10px;
            flex-shrink: 0;
            margin-bottom: 10px;
          }
  
          .info {
            color: #8A8A8A;
            font-size: 12.871px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.149px;
            margin-left: 10px;
            text-align: left;
          }        
  
        }
  
        .right {
          flex: 2;
          margin-top: 20px;
          margin-bottom: 20px;
  
          .top1 {
            color: #060606;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.294px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
  
          form {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 30px;
            justify-content: space-around;
  
            .formInput {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
  
              label {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-right: 30px;
                color: #4B4B4B;
                font-size: 14.654px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.207px;
  
                .icon {
                  cursor: pointer;
                }
              }
  
              input {
                display: flex;
                width: auto;
                height: var(--32, 32px);
                padding: var(--12, 12px) var(--16, 16px);
                align-items: center;
                gap: var(--8, 8px);
                border-radius: var(--8, 8px);
                border: 1px solid var(--Neutral-2, #D7D7DB);
                background: var(--white-100, #FFF);
                color: var(--Body-text, #4F5672);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }
            }
  
            button {
              width: 40%;
              padding: 10px;
              border: none;
              background-color: #1B40C4;
              color: white;
              font-weight: bold;
              cursor: pointer;
              margin-top: 10px;
              font-size: 14px;
              border-radius: 8px;
              color: rgba(255, 255, 255, 0.63);
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.207px;
  
              &:disabled{
                background-color: rgba(27, 64, 196, 0.322);
                cursor: not-allowed;
              }
            }

            .payment-options {
              border-radius: var(--16, 16px);
              background: var(--white-100, #FFF);
              box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
              margin-bottom: 40px;
              overflow: hidden;
            }
        
            .payment-options1 {
              border-radius: var(--16, 16px);
              background: var(--white-100, #FFF);
              box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
              margin-bottom: 20px;
              overflow: hidden;
              padding-top: 10px;
              padding-bottom: 10px;
            }
        
            .best-value {
              display: inline-flex;
              padding-left: 20px;
              justify-content: flex-end;
              align-items: center;
              gap: 96.21px;
              background: #E1E9FC;
              margin-top: 20px;
              position: relative; /* Required for absolute positioning of ::after pseudo-element */
            }
            
            .best-value::after {
              content: "";
              width: 25px;
              height: 25px;
              transform: rotate(-45deg);
              background: var(--white-100, #FFF);
              position: absolute;
              top: 0;
              right: -10px; /* Adjust the value as needed */
            }    
        
            .best-text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: #336AEA;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 25px; /* 192.308% */
            }
        
            .payment-text {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 30px;
            }
        
            .row-payment {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
        
            .subscribe-button {
              display: flex;
              padding: 0;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              border-radius: 5px;
              background: #336AEA;
              margin-bottom: 40px;
              border: none;
              cursor: pointer;
            }
        
            .subscribe-text {
              color: var(--white-100, #FFF);
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 44px;
            }
        
            .year-text {
              color: #121212;
              font-weight: 700;
              text-transform: uppercase;
              margin-left: 10px;
              margin-top: 10px;
              font-size: 13.864px;
              font-style: normal;
              font-weight: 700;
              line-height: 22.183px;
            }
        
            .money-row{
              display: flex;
              flex-direction: row;
              margin-left: 10px;
              margin-top: 10px;
            }
        
            .price {
              color: #121212;
              text-transform: uppercase;
              font-size: 29.067px;
              font-style: normal;
              font-weight: 700;
              line-height: 21.8px;
            }
        
            .month-text {
              color: #424242;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              margin-top: 3px;
              margin-left: 3px;
            }
        
            .terms-text1 {
              color: #777;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              margin-left: 10px;
              margin-top: 15px;
            }
        
            .terms-text2 {
              color: #777;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              margin-left: 10px;
              margin-bottom: 10px;
            }
        
            .custom-color {
              color: #121212;
              margin-left: 0px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 18px; /* 150% */
            }

            .custom-color1 {
              color: #121212;
              margin-left: 10px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 18px; /* 150% */
            }

          }
        }
      }
    }
  }

}