.sidebar {
  flex: 1;
  border-right: 1px solid rgb(100, 100, 100);
  background: #0A112E;
  z-index: 2;
  min-height: 100dvh;

  .top {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
    }

    .logo{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
    }

    .logo-name{
      color: var(--white-100, #FFF);
      font-size: 25.31px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.253px;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid #0A112E;
  }

  .center {
    // padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      margin-left: 20px;

      .title {
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        width: 190px;
        height: 59px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #1B40C4;
        // margin-top: 20px;
      }      

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        justify-content: flex-start; /* Change from center to flex-start */
      
        .icon {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.63);
        }
      
        span {
          margin-left: 10px;
          color: rgba(255, 255, 255, 0.63);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 118.023%; /* 19.452px */
        }
      
        .current {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #FFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 118.023%; /* 22.424px */
        }
      }      
    }
  }

  .logout {
    background: #0A112E; /* Background color */
    border: 1px solid #0A112E; /* Border color */
    font-size: 20px;
    font-weight: 600;
    color: #888;
    cursor: pointer; /* Show pointer cursor on hover */
    padding-left: 10px;
    color: #fff;
  }

  .logout:hover {
    background: #0A112E; /* Change background color on hover */
    border-color: #0A112E; /* Change border color on hover */
    color: #fff; /* Change text color on hover */
  }

  .bottom{
      display: flex;
      align-items: center;
      margin: 10px;

      .colorOption{
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #43A921;
          cursor: pointer;
          margin: 5px;

          &:nth-child(1){
              background-color: whitesmoke;
          }
          &:nth-child(2){
              background-color: #333;
          }
          &:nth-child(3){
              background-color: darkblue;
          }
      }
  }
}

@media only screen and (max-width: 768px) {

  .sidebar {
    flex: 1;
    border-right: 1px solid rgb(100, 100, 100);
    min-height: auto;
    background: #0A112E;
  
    .top {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .logo-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;
      }
  
      .logo{
        width: 18.786px;
        height: 24.77px;
        flex-shrink: 0;
        margin-right: 5px;
      }
  
      .logo-name{
        color: var(--white-100, #FFF);
        font-size: 25.31px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.253px;
      }
    }
  
    hr {
      height: 0;
      border: 0.5px solid #0A112E;
    }
  
    .center {
      // padding-left: 10px;
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        margin-left: 1px;
  
        .title {
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: center;
          width: 150px;
          height: 50px;
          flex-shrink: 0;
          border-radius: 10px;
          background: #1B40C4;
          // margin-top: 20px;
        }      
  
        li {
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
          justify-content: flex-start; /* Change from center to flex-start */
        
          .icon {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.63);
            width: 14px;
            height: auto;
          }
        
          span {
            margin-left: 10px;
            color: rgba(255, 255, 255, 0.63);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 118.023%; /* 19.452px */
          }
        
          .current {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 118.023%; /* 22.424px */
          }
        }      
      }
    }
  
    .logout {
      background: #0A112E; /* Background color */
      border: 1px solid #0A112E; /* Border color */
      font-size: 14px;
      font-weight: 600;
      color: #888;
      cursor: pointer; /* Show pointer cursor on hover */
      padding-left: 10px;
      color: #fff;
    }
  
    .logout:hover {
      background: #0A112E; /* Change background color on hover */
      border-color: #0A112E; /* Change border color on hover */
      color: #fff; /* Change text color on hover */
    }
  
    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;
  
        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #43A921;
            cursor: pointer;
            margin: 5px;
  
            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
            &:nth-child(3){
                background-color: darkblue;
            }
        }
    }
  }

 }