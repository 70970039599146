.beast {
    display: flex;
    width: 100%;
    background: #fff;
    .listContainer1 {
      flex: 6;
    }
  }

  @media only screen and (max-width: 768px) {

    .beast {
      width: auto;
      display: flex;
      background: #fff;
  
      .sidebar {
        display: none; /* Hide the sidebar */
      }
    }  
  }