.container1{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .password-toggle{
      position: absolute;
      left:84%; /* Adjust as needed */
      cursor: pointer;
      user-select: none;
      bottom: 28%;
    }

    .login-image-container {
      background-color: #1B40C4;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 100vh;
    }

    .circle-container {
      position: relative;
    }

    .outer-circle {
      animation: rotate 10s linear infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotate(-25deg)
      }
      100% {
        transform: rotate(25deg) translateX(12px);
      }
    }
    
    .inner-circle-container {
      position: absolute;
      top: 65%;
      left: 47%;
      transform: translate(-50%, -50%);
    }

    // .login-bg-img{
    //   width: 25dvw;
    //   flex-shrink: 0;
    //   animation: rotateAnimation 10s linear infinite;
    // }

    .login-image-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 50px;
    }

    .login-text1 {
      color: var(--white-100, #FFF);
      font-size: 24.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
      margin-bottom: 5px;
    }

    .text4{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      z-index: 1;
    }

    .text4 .underline {
      color: #1B40C4;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration: underline;
      cursor: pointer;
    }

    .login-text2 {
      color: var(--white-100, #FFF);
      font-size: 16.045px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.14px;
    }

    .login{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      background-color: #FFF;
    }

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 280px;
      margin-bottom: 50px;
    }

    .logo1{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
    }

    .logo2 {
      display: none;
    }

    .logo-name{
      color: #1B40C4;
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .idk{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .input-header {
      color: #2E54FF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.364px;
      margin-right: 10px;
      text-align: right;
      cursor: pointer;
      display: inline;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 30px;
      margin-right: 60px;
    }

    .text1 {
    color: #000;
    font-size: 26.28px;
    font-style: normal;
    font-weight: bold;
    line-height: 31.017px;
    }

    .text2 {
    color: #595959;
    font-size: 18.101px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.364px;
    margin-top: 5px;
    }

    form{
      display: flex;
      flex-direction: column;
      z-index: 1;

      .password-container{
        width: 100%;
        position: relative;
      }

      input{
        margin: 10px;
        display: flex;
        width: 400px;
        height: 20px;
        padding: 17.026px 22.701px;
        align-items: center;
        gap: 11.35px;
        border-radius: 11.35px;
        border: 1.419px solid var(--neutral-2, #D7D7DB);
        background: var(--White, #FFF);
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
      }

      .button1{
        font-size: 16px;
        border: none;
        border-radius: 10px;
        background: #E5E5E5;
        color: #525252;
        font-weight: bold;
        cursor: pointer;
        flex-shrink: 0;
        padding: 10px 30px 10px 30px;
      }

      .button2{
        font-size: 16px;
        border: none;
        border-radius: 10px;
        background: #2E54FF;
        color: white;
        font-weight: bold;
        cursor: pointer;
        flex-shrink: 0;
        padding: 15px 20px 15px 20px;
        width: 100%;
        margin-top: 20px;
      }

      .login-img {
        margin-left: 5px;
      }

      span{
        font-size: 16px;
        color: red;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    .text3 {
      color: #4A4B4F;
      font-size: 17.519px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.364px;
      margin-top: 40px;
      z-index: 1;
      cursor: pointer;
      }

      .login-link {
        color: #2E54FF;
        cursor: pointer;
      }

      .sidebar-btn {
        display: none;
      }
}

/* Media query for smaller screens (e.g., mobile devices) */
@media only screen and (max-width: 768px) {

  .container1{
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: auto;

    .password-toggle{
      position: absolute;
      left:83%; /* Adjust as needed */
      cursor: pointer;
      user-select: none;
      bottom: 28%;
    }

    .login-image-container {
      background-color: #1B40C4;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: none;
    }
    
    .login{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #FFF;
      width: 100dvw;
      height: auto;
    }

    .sidebar {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: column;
      padding-top: 50px;
      border: none;
      box-shadow: none;
    }

    .sidebar-btn {
      background-color: #1B40C4;
      border: none;
      box-shadow: none;
      cursor: pointer;
      padding-left: 30%;
      display: flex;
      z-index: 1;
    }

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #1B40C4;
      margin-right: 0;
      padding: 20px 100%;
      margin-left: 0;
    }

    .logo1{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
      display: none;
    }

    .button5{
      color: #FB591E;
      font-size: 16.918px;
      font-style: normal;
      font-weight: 500;
      line-height: 12.478px; /* 114.286% */
      background: none;
      margin-right: 0px;
      box-shadow: none;
      border: none;
      cursor: pointer;
      margin: 20px;
    }

    .button6{
      display: flex;
      padding: 10.416px 15.573px;
      justify-content: center;
      align-items: center;
      gap: 4.629px;
      border-radius: 6.629px;
      background: #FB591E;
      margin-left: 0px;
      color: #FAFAFB;
      font-size: 14.416px;
      font-style: normal;
      font-weight: 700;
      line-height: 13.888px;
      box-shadow: none;
      border: none;
      cursor: pointer;
      margin: 20px;
    }

    .home-text {
      color: var(--white-100, #FFF);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      box-shadow: none;
      border: none;
      background: none;
      margin-left: auto;
      cursor: pointer;
      margin-right: 70px;
      padding-bottom: 5px;
      margin: 20px;
    }  

    .logo2 {
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
      display: flex;
    }

    .logo-name{
      color: var(--white-100, #FFF);
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .input-header {
      color: #2E54FF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.364px;
      margin-right: 10px;
      text-align: right;
      cursor: pointer;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      margin: 20px;
    }

    .text1 {
    color: #000;
    font-size: 22.28px;
    font-style: normal;
    font-weight: bold;
    line-height: 31.017px;
    }

    .text2 {
    color: #595959;
    font-size: 16.101px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.364px;
    margin-top: 5px;
    }

    form{
      display: flex;
      flex-direction: column;
      margin: 20px;
      z-index: 1;

      input{
        margin: 10px;
        display: flex;
        padding: 17.026px 22.701px;
        align-items: center;
        gap: 11.35px;
        border-radius: 11.35px;
        border: 1.419px solid var(--neutral-2, #D7D7DB);
        background: var(--White, #FFF);
        width: 300px;
        height: 20px;
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
      }

      .button1{
        font-size: 16px;
        border: none;
        border-radius: 10px;
        background: #E5E5E5;
        color: #525252;
        font-weight: bold;
        cursor: pointer;
        flex-shrink: 0;
        padding: 10px 30px 10px 30px;
      }

      .button2{
        font-size: 16px;
        border: none;
        border-radius: 10px;
        background: #2E54FF;
        color: white;
        font-weight: bold;
        cursor: pointer;
        flex-shrink: 0;
        padding: 15px 20px 15px 20px;
        width: 100%;
        margin-top: 20px;
      }

      .login-img {
        margin-left: 5px;
      }

      span{
        font-size: 16px;
        color: red;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    .text3 {
      color: #4A4B4F;
      font-size: 17.519px;
      font-style: normal;
      font-weight: 400;
      line-height: 21.364px;
      margin-top: 40px;
      }

      .login-link {
        color: #2E54FF;
        cursor: pointer;
      }
}

}