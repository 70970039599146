.slider {
    flex: 4;
}

.slidelogo {
    width: 40px;
    height: 40px;
    margin: 20px;
  }

@media only screen and (max-width: 768px) {

    .slider {
        flex: 4;
    }

    .hello{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    
    .slidelogo {
        width: 30px;
        height: 30px;
        margin: 15px;
      }

 }