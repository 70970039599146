.Referal {
  width: 100%;
  display: flex;
  background: #F3F6FB;

  .newContainer {
    flex: 6;

    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #525151;
      text-align: center;
      font-size: 22.72px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.227px;
      padding: 20px;
      margin: 50px;
    }

    .bottom {
      border-radius: 20px;
    }

    .card-container {
      padding: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .referalCard {
        flex-shrink: 0;
        border-radius: 9px;
        background: var(--white-100, #FFF);
        box-shadow: 0px 4px 34.7px 0px rgba(0, 0, 0, 0.25);
      }

      .cardHead {
        text-align: center;
        color: #525151;
        text-align: center;
        font-size: 22.72px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.227px;
        padding: 20px;
      }

      .copy-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
      }

      .copy-container1 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 30px 30px 30px;
      }

      .copy {
        display: flex;
        width: 502.674px;
        padding: 9.685px;
        align-items: center;
        gap: 9.685px;
        border: 0.969px solid #1B40C4;
        color: #868686;
        font-size: 22.005px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.22px;
        z-index: 1;
      }

      .copy-button {
        display: flex;
        width: auto;
        padding: 10.985px;
        justify-content: center;
        align-items: center;
        gap: 9.685px;
        border-radius: 0px 3.874px 3.874px 0px;
        background: #1B40C4;
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 22.005px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.22px;
        border: none;
        cursor: pointer;
        z-index: 1;
      }

      .copy-button1 {
        display: flex;
        width: auto;
        padding: 10.985px;
        justify-content: center;
        align-items: center;
        gap: 9.685px;
        border-radius: 0px 3.874px 3.874px 0px;
        background: #1B40C4;
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 22.005px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.22px;
        border: none;
        cursor: pointer;
        z-index: 1;
      }

      .share-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
      }

      .share-text {
        color: #828282;
        text-align: center;
        font-size: 22.72px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.227px;
        margin-right: 20px;
      }

      .share-icon {
        margin-right: 20px;
        z-index: 1;
      }

      .left {
        flex: 1;
        text-align: center;

        .image-container{
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 7%;
          object-fit: cover;
          margin-right: 10px;
          margin-top: 10px;
          border: 0.4px solid #1B40C4; /* Use border property for stroke */
        }

        .image-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        button {
          padding: 10px;
          border: none;
          border-radius: 7px;
          background: #1B40C4;
          color: rgba(255, 255, 255, 0.63);
          font-size: 20.654px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.207px;
          cursor: pointer;
          margin-top: 10px;
          font-size: 14px;
          margin-left: 10px;
          width: 140px;
          height: 41px;
          padding: 10px 10px 10px 20px;
          gap: 10px;
          flex-shrink: 0;
          margin-bottom: 10px;
        }

        .info {
          color: #8A8A8A;
          font-size: 14.871px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.149px;
          margin-left: 10px;
          text-align: left;
        }        

      }

      .right {
        flex: 2;
        margin-top: 20px;
        margin-bottom: 20px;

        .top1 {
          color: #060606;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.294px;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        form {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          gap: 30px;
          justify-content: space-around;

          .formInput {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-right: 50px;
              color: #4B4B4B;
              font-size: 20.654px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.207px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              display: flex;
              width: 387px;
              height: var(--32, 32px);
              padding: var(--12, 12px) var(--16, 16px);
              align-items: center;
              gap: var(--8, 8px);
              border-radius: var(--8, 8px);
              border: 1px solid var(--Neutral-2, #D7D7DB);
              background: var(--white-100, #FFF);
              color: var(--Body-text, #4F5672);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
          }

          button {
            width: 30%;
            padding: 10px;
            border: none;
            background-color: #1B40C4;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
            font-size: 16px;
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.63);
            font-size: 20.654px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.207px;

            &:disabled{
              background-color: rgba(27, 64, 196, 0.322);
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {

  .Referal {
    width: 100%;
    display: flex;
    background: #fff;

    .sidebar {
      display: none; /* Hide the sidebar */
    }
  
    .newContainer {
      flex: 6;
  
      .top {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #525151;
        text-align: center;
        font-size: 16.72px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.227px;
        padding: 20px;
        margin: 50px;
      }
  
      .bottom {
        border-radius: 20px;
      }
  
      .card-container {
        padding: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        .referalCard {
          flex-shrink: 0;
          border-radius: 9px;
          background: var(--white-100, #FFF);
          box-shadow: 0px 4px 34.7px 0px rgba(0, 0, 0, 0.25);
        }
  
        .cardHead {
          text-align: center;
          color: #525151;
          text-align: center;
          font-size: 16.72px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.227px;
          padding: 20px;
        }
  
        .copy-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px;
        }
  
        .copy {
          display: flex;
          width: auto;
          padding: 9.685px;
          align-items: center;
          gap: 9.685px;
          border: 0.969px solid #1B40C4;
          color: #868686;
          font-size: 16.005px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.22px;
          z-index: 1;
        }
  
        .copy-button {
          display: flex;
          width: auto;
          padding: 11px;
          justify-content: center;
          align-items: center;
          gap: 9.685px;
          border-radius: 0px 3.874px 3.874px 0px;
          background: #1B40C4;
          color: var(--white-100, #FFF);
          text-align: center;
          font-size: 16.005px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.22px;
          border: none;
          cursor: pointer;
          z-index: 1;
        }

        .copy-button1 {
          display: flex;
          width: auto;
          padding: 11px;
          justify-content: center;
          align-items: center;
          gap: 9.685px;
          border-radius: 0px 3.874px 3.874px 0px;
          background: #1B40C4;
          color: var(--white-100, #FFF);
          text-align: center;
          font-size: 16.005px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.22px;
          border: none;
          cursor: pointer;
          z-index: 1;
        }
  
        .share-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 40px;
        }
  
        .share-text {
          color: #828282;
          text-align: center;
          font-size: 16.72px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.227px;
          margin-right: 10px;
        }
  
        .share-icon {
          margin-right: 15px;
          z-index: 1;
        }
  
        .left {
          flex: 1;
          text-align: center;
  
          .image-container{
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
          }
  
          img {
            width: 150px;
            height: 150px;
            border-radius: 7%;
            object-fit: cover;
            margin-right: 10px;
            margin-top: 10px;
            border: 0.4px solid #1B40C4; /* Use border property for stroke */
          }
  
          .image-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
  
          button {
            padding: 10px;
            border: none;
            border-radius: 7px;
            background: #1B40C4;
            color: rgba(255, 255, 255, 0.63);
            font-size: 20.654px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.207px;
            cursor: pointer;
            margin-top: 10px;
            font-size: 14px;
            margin-left: 10px;
            width: 140px;
            height: 41px;
            padding: 10px 10px 10px 20px;
            gap: 10px;
            flex-shrink: 0;
            margin-bottom: 10px;
          }
  
          .info {
            color: #8A8A8A;
            font-size: 14.871px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.149px;
            margin-left: 10px;
            text-align: left;
          }        
  
        }
  
        .right {
          flex: 2;
          margin-top: 20px;
          margin-bottom: 20px;
  
          .top1 {
            color: #060606;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.294px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
  
          form {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 30px;
            justify-content: space-around;
  
            .formInput {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
  
              label {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-right: 50px;
                color: #4B4B4B;
                font-size: 20.654px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.207px;
  
                .icon {
                  cursor: pointer;
                }
              }
  
              input {
                display: flex;
                width: 387px;
                height: var(--32, 32px);
                padding: var(--12, 12px) var(--16, 16px);
                align-items: center;
                gap: var(--8, 8px);
                border-radius: var(--8, 8px);
                border: 1px solid var(--Neutral-2, #D7D7DB);
                background: var(--white-100, #FFF);
                color: var(--Body-text, #4F5672);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }
            }
  
            button {
              width: 30%;
              padding: 10px;
              border: none;
              background-color: #1B40C4;
              color: white;
              font-weight: bold;
              cursor: pointer;
              margin-top: 10px;
              font-size: 16px;
              border-radius: 8px;
              color: rgba(255, 255, 255, 0.63);
              font-size: 20.654px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.207px;
  
              &:disabled{
                background-color: rgba(27, 64, 196, 0.322);
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
  }   

}