.container{
    background: linear-gradient(72deg, #1D46D6 5.85%, #0A112D 52.36%);
    width: 100%;
    
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 100px 20px 70px;
    }
    
    .start-line {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);
      width: 90%;
      margin: auto;
    }

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
    }

    .logo{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
    }

    .logo-name{
      color: var(--white-100, #FFF);
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .search {
      display: flex;
      width: 20%;
      height: 20px;
      padding: 10px 18px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 25px;
      border: 0.4px solid var(--white-100, #FFF);
      background: rgba(120, 120, 120, 0.40);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-left: 260px;
      margin-right: 20px;
      z-index: 1;
    }

    .search-box {
      background: none;
      border: none;
      width: 90%;
      height: 35px;
      box-shadow: none;
      outline: none;
    }
    
    .search-box:focus {
      border: 1px solid transparent;
    }    

    .search-icon-container {
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 50px;
      background: #FB591E;
    }

    .search-icon {
      width: 15.375px;
      height: 15.375px;
      flex-shrink: 0;
      fill: var(--white-100, #FFF);
    }  

    .login{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

      .button-container {
        display: flex;
        align-items: center;
      }

      .button1{
        color: #FB591E;
        font-size: 16.918px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.478px; /* 114.286% */
        background: none;
        margin-right: 30px;
        box-shadow: none;
        border: none;
        cursor: pointer;
        z-index: 1;
      }

      .button2{
        display: flex;
        padding: 10.416px 11.573px;
        justify-content: center;
        align-items: center;
        gap: 4.629px;
        border-radius: 8.629px;
        background: #FB591E;
        margin-left: 30px;
        color: #FAFAFB;
        font-size: 16.416px;
        font-style: normal;
        font-weight: 700;
        line-height: 13.888px;
        box-shadow: none;
        border: none;
        cursor: pointer;
        z-index: 1;
      }

      .home-text1 {
        color: var(--white-100, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: none;
        border: none;
        background: none;
        margin-left: auto;  /* Push the button to the right as much as possible */  /* Reset the right margin */
        cursor: pointer;
        margin-right: 50px;
        padding-bottom: 5px;
        z-index: 1;
      }  

      .home-text2 {
        color: var(--white-100, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: none;
        border: none;
        background: none;
        margin-left: auto;  /* Push the button to the right as much as possible */  /* Reset the right margin */
        cursor: pointer;
        margin-right: 50px;
        padding-bottom: 5px;
        z-index: 1;
      }  

      .center-container {
        display: flex;
        justify-content: center;
        margin-top: 20dvh;
        align-items: center;
        flex-direction: column;
        margin-bottom: 35dvh;
      }

      .center-container1 {
        display: flex;
        padding: 10.231px 5%;
        justify-content: center;
        align-items: center;
        gap: 7.231px;
        border-radius: 36.155px;
        background: rgba(255, 255, 255, 0.22);
      }

      .center-text1 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 16.016px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .center-text2 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.268px 0.489px rgba(0, 0, 0, 0.84);
        font-size: 80.44px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-top: 20px;
      }

      .center-text3 {
        color: var(--white-100, #FFF);
        font-size: 100.931px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -15px;
      }

      .center-text4 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
      }

      .center-container2 {
        display: flex;
        width: 253px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: #FB591E;
        margin-top: 60px;
        cursor: pointer;
        z-index: 1;
      }

      .center-text5 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .logoSlide {
        overflow-x: hidden;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #3E69FF;
        border-bottom: 1px solid #3E69FF;
        background: #000;
      }      

      .animated {
        background: #0A112E;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100dvh;
      }

      .animated-text-container{
        margin: 100px 50px 100px 100px;
      }

      .an-text-1 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.268px 0.489px rgba(0, 0, 0, 0.84);
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-bottom: 20px;
      }

      .blue-line {
        width: 91px;
        height: 3px;
        flex-shrink: 0;
        border-radius: 9px;
        background: #193CB8;
        margin-bottom: 30px;
      }

      .an-text-2 {
        color: var(--white-100, #FFF);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 50px;
      }

      .bg-animated {
        width: 637.922px;
        height: 315.279px;
        transform: rotate(-9.187deg);
        flex-shrink: 0;
        border-radius: 637.922px;
        background: #1C44D0;
        filter: blur(151.28286743164062px);
      }

      .animated-world-container{
        margin: 100px 100px 100px 0px;
      }

      .world {
        width: 417px;
        height: 401.725px;
        flex-shrink: 0;
      }

      .world-shadow {
        width: 452px;
        height: 49px;
        flex-shrink: 0;
        border-radius: 452px;
        background: rgba(0, 0, 0, 0.65);
        filter: blur(25px);
        margin-top: 20px;
      }

      .business {
        background: #0A112E;
        height: auto;
        overflow-x: hidden;
        display: flex;
      }

      .business2 {
        background: #0A112E;
        height: auto;
        overflow-x: hidden;
        display: none;
      }

      .business::-webkit-scrollbar {
        width: 0.5em;
      }
      
      .business::-webkit-scrollbar-thumb {
        background-color: #0A112E;
        outline: 1px solid #0A112E;
      }

      .business1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }

      .business-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }

      .business-text-container{
        padding: 100px 100px 40px 100px;
        background-color: #0A112E;
        
      }

      .bs-text-1 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.268px 0.489px rgba(0, 0, 0, 0.84);
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }

      .bs-text-2 {
        color: #F4F4F4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .business-world-container {
        margin: 20px 100px 0px 100px;
      }

      .business-world1-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .no-business{
        padding-left: 100px;
        padding-right: 100px;
        background-color: #0A112E;
        color: var(--white-100, #FFF);
        font-size: 26px;
      }

      .business-logo-container {
        border-radius: 9px;
        border: 1px solid #1A3EBD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-right: 69px;
        margin-bottom: 80px;
      }

      .bs-name {
        color: #F4F4F4;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0px 10px 10px 10px;
        max-width: 100px; /* Adjust as needed */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // word-wrap: break-word;
      }

      .bs-logo {
        width: 80px;
        height: 80px;
        margin: 20px;
      }

      .bs-button {
        display: flex;
        width: 99px;
        height: 27px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 50px;
        background: #1A3EBD;
        border: none;
        cursor: pointer;
        margin-bottom: 20px;
      }

      .see-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background: #0A112E;
      }

      .bt-see {
        display: flex;
        width: 131px;
        height: 27px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 50px;
        background: #1A3EBD;
        color: var(--white-100, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 40px 150px 0px 60px;
        border: none;
        cursor: pointer;
      }

      .price {
        background: #0A112E;
      }

      .price1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .price-text-container{
        margin: 120px 10px 40px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .pr-text-1 {
        color: #1A3EBD;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 17.362px; /* 66.776% */
        margin-bottom: 20px;
      }

      .pr-text-2 {
        color: var(--white-100, #FFF);
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 37.499px; /* 93.748% */
        margin-bottom: 20px;
      }

      .pr-text-3 {
        color: var(--white-100, #FFF);
        font-size: 17.362px;
        font-style: normal;
        font-weight: 400;
        line-height: 17.362px; /* 100% */
        margin-bottom: 40px;
      }

      .price-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0px 10px;
        z-index: 2;
        flex-wrap: wrap;
      }

      .price-info-container1 {
        border-radius: 11px;
        border: 0.3px solid #1A3EBD;
        background: rgba(30, 30, 30, 0.61);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 80px;
        margin-right: 15%;
      }

      .price-info-container2 {
        border-radius: 11px;
        border: 0.3px solid #1A3EBD;
        background: rgba(30, 30, 30, 0.61);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 80px;
      }

      .price-info-container3 {
        border-radius: 11px;
        border: 0.3px solid #1A3EBD;
        background: rgba(30, 30, 30, 0.61);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 100px;
      }

      .basic-text {
        color: #A1A1A1;
        font-size: 17.362px;
        font-style: normal;
        font-weight: 600;
        line-height: 17.362px; /* 100% */
        margin: 20px 0px 0px 20px;
      }

      .price-text {
        color: var(--white-100, #FFF);
        font-size: 31.251px;
        font-style: normal;
        font-weight: 600;
        line-height: 31.251px; /* 100% */
        margin: 10px 0px 10px 20px;
      }

      .price-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 10px 20px 20px;
      }

      .price-logo {
        width: 16px;
        height: 9.722px;
        flex-shrink: 0;
        margin-right: 5px;
      }

      .info-text {
        color: var(--white-100, #FFF);
        font-size: 17.201px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .price-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        padding: 10px;
        gap: 10px;
        border-radius: var(--4, 4px);
        border: 1px solid #1A3EBD;
        margin: 20px 15px 50px 8%;
        cursor: pointer;
      }

      .bt-price-text {
        color: var(--white-100, #FFF);
        font-size: 14.201px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .cube1 {
        position: absolute;
        top: 85%;
        left: 16%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .cube2 {
        position: absolute;
        top: 1%;
        left: 83%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .rating {
        background: #0A112E;
      }

      .rating1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .rating-text-container{
        margin: 200px 100px 40px 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .rt-text-1 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 32.022px;
        font-style: normal;
        font-weight: 900;
        line-height: 35.58px;
      }

      .rt-text-2 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 35.58px;
      }

      .rating-world-container {
        margin: 10px 80px 0px 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
      }

      .rating-container1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px 150px;
      }

      .review-container {

      }

      .review-text {
        color: var(--white-100, #FFF);
        font-size: 16.232px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.348px;
      }

      .reviewer-name {
        color: #040D36;
        font-size: 16.232px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.348px;
        margin-top: 20px;
      }

      .review-name {
        color: #7C7E84;
        font-size: 16.453px;
        font-style: italic;
        font-weight: 400;
        line-height: 17.79px;
        margin-top: 5px;
      }

      .review-logo {
        margin-top: 5px;
      }

      .top-up {
        position: fixed;
        bottom: 240px;
        right: 100px;
        background-color: #1A3EBD;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 16px;
        cursor: pointer;
        border-radius: 5px;
        display: none; /* Hide by default */
        transition: opacity 0.3s ease-in-out; /* Smooth transition */
      }

      .top-up1 {
        position: fixed;
        bottom: 60px;
        right: 100px;
        background-color: #1A3EBD;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 16px;
        cursor: pointer;
        border-radius: 5px;
        display: none; /* Hide by default */
        transition: opacity 0.3s ease-in-out; /* Smooth transition */
        display: block;
      }

      /* Show the button when isVisible state is true */
      .top-up.show {
        display: block;
      }

      /* Show the button when isVisible state is true */
      .top-up1.show1 {
        display: block;
      }

      .arrow {
        margin-right: 5px; /* Space between arrow symbol and text */
      }

      .chatbot-img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-right: 5px;
        fill: white;
      }

      /* Hover animation */

      .top-up1:hover {
        background-color: #1A3EBD; /* New background color */
        animation: bounce 0.5s ease infinite alternate; /* Bouncing animation */
      }

      .top-up:hover {
        background-color: #1A3EBD; /* New background color */
        animation: bounce 0.5s ease infinite alternate; /* Bouncing animation */
      }

      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-5px);
        }
      }

      .footer {
        background: #0A112E;
      }

      .footer1 {
        display: flex;
        align-items: flex-start;
        justify-content: baseline;
        flex-direction: column;
      }

      .ft-text-container{
        margin: 300px 100px 40px 100px;
      }

      .footer-img-container {
        display: flex;
        align-items: flex-start;
        justify-content: start;
      }
  
      .footer-logo{
        width: 18.786px;
        height: 24.77px;
        flex-shrink: 0;
        margin-right: 5px;
      }
  
      .footer-logo-name{
        color: var(--white-100, #FFF);
        font-size: 20.654px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.207px;
      }

      .copy-text {
        color: var(--White, var(--white-100, #FFF));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        cursor: pointer;
      }

      .review-main-contianer{
        margin-bottom: 10px;
      }

      .copy-text .underline {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
        cursor: pointer;
      }      

      .footer-text-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px 100px 50px;
      }

      .footer-help {
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-left: 50px;
        cursor: pointer;
      }

      .footer-link-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .sidebar-btn {
        display: none;
      }
}

@media only screen and (max-width: 768px) {

  .container{
    background: linear-gradient(72deg, #1D46D6 5.85%, #0A112D 52.36%);
    width: 100%;
    
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 20px;
    }

    .start-line {
      display: none;
    }

    .sidebar {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: column;
      padding-top: 50px;
      border: none;
      box-shadow: none;
    }

    .sidebar-btn {
      background-color: #0A112D;
      border: none;
      box-shadow: none;
      cursor: pointer;
      display: flex;
      padding-left: 25%;
    }

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logo{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 0;
    }

    .logo-name{
      color: var(--white-100, #FFF);
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .search {
      display: flex;
      padding: 0px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 25px;
      border: none;
      background: none;
      box-shadow: none;
      margin-left: 25%;
      margin-right: 0px;
      z-index: 1;
    }

    .search-box {
      background: none;
      border: none;
      width: 90%;
      height: 35px;
      box-shadow: none;
      outline: none;
      display: none;
    }
    
    .search-box:focus {
      border: 1px solid transparent;
    }    

    .search-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 50px;
      background: #0A112D;
    }

    .search-icon {
      width: 22.375px;
      height: 22.375px;
      flex-shrink: 0;
      fill: var(--white-100, #FFF);
    }  

    .login{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

      .button-container {
        display: flex;
        align-items: center;
        display: none;
      }

      .button1{
        color: #FB591E;
        font-size: 16.918px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.478px; /* 114.286% */
        background: none;
        margin-right: 0px;
        box-shadow: none;
        border: none;
        cursor: pointer;
        margin: 20px;
      }

      .button2{
        display: flex;
        padding: 10.416px 15.573px;
        justify-content: center;
        align-items: center;
        gap: 4.629px;
        border-radius: 6.629px;
        background: #FB591E;
        margin-left: 0px;
        color: #FAFAFB;
        font-size: 14.416px;
        font-style: normal;
        font-weight: 700;
        line-height: 13.888px;
        box-shadow: none;
        border: none;
        cursor: pointer;
        margin: 20px;
      }

      .home-text1 {
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: none;
        border: none;
        background: none;
        margin-left: auto;
        cursor: pointer;
        margin-right: 70px;
        padding-bottom: 5px;
        margin: 20px;
      }  

      .center-container {
        display: flex;
        justify-content: center;
        margin-top: 20dvh;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20dvh;
      }

      .center-container1 {
        display: flex;
        padding: 10.231px 5%;
        justify-content: center;
        align-items: center;
        gap: 7.231px;
        border-radius: 36.155px;
        background: rgba(255, 255, 255, 0.22);
      }

      .center-text1 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 14.016px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .center-text2 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.212px 0.386px rgba(0, 0, 0, 0.84);
        font-size: 43.078px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-top: 20px;
      }

      .center-text3 {
        color: var(--white-100, #FFF);
        font-size: 45.378px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -5px;
      }

      .center-text4 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 15px 10px 0px 10px;
      }

      .verthe-break{
        display: none;
      }

      .center-container2 {
        display: flex;
        width: 253px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 50px;
        background: #FB591E;
        margin-top: 60px;
        cursor: pointer;
      }

      .center-text5 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .logoSlide {
        overflow-x: hidden;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #3E69FF;
        border-bottom: 1px solid #3E69FF;
        background: #000;
      }      

      .animated {
        background: #0A112E;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: auto;
      }

      .animated-text-container{
        margin: 20px 30px 100px 30px;
        order: 2
      }

      .an-text-1 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.268px 0.489px rgba(0, 0, 0, 0.84);
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-bottom: 20px;
      }

      .blue-line {
        width: 91px;
        height: 3px;
        flex-shrink: 0;
        border-radius: 9px;
        background: #193CB8;
        margin-bottom: 30px;
      }

      .an-text-2 {
        color: var(--white-100, #FFF);
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 10px;
      }

      .bg-animated {
        width: 637.922px;
        height: 315.279px;
        transform: rotate(-9.187deg);
        flex-shrink: 0;
        border-radius: 637.922px;
        background: #1C44D0;
        filter: blur(151.28286743164062px);
      }

      .animated-world-container{
        margin: 100px 0px 100px 0px;
      }

      .world {
        width: 300px;
        height: 300.725px;
        flex-shrink: 0;
      }

      .world-shadow {
        width: 300px;
        height: 49px;
        flex-shrink: 0;
        border-radius: 452px;
        background: rgba(0, 0, 0, 0.65);
        filter: blur(25px);
        margin-top: 20px;
      }

      .business {
        background: #0A112E;
        overflow-x: auto;
        height: auto;
        display: none;
      }

      .business2 {
        background: #0A112E;
        overflow-x: auto;
        height: auto;
        display: flex;
      }

      .business::-webkit-scrollbar {
        width: 0.5em;
      }

      .business2::-webkit-scrollbar {
        width: 0.5em;
      }
      
      .business::-webkit-scrollbar-thumb {
        background-color: #0A112E;
        outline: 1px solid #0A112E;
      }

      .business2::-webkit-scrollbar-thumb {
        background-color: #0A112E;
        outline: 1px solid #0A112E;
      }

      .no-business{
        padding-left: 30px;
        padding-right: 30px;
        background-color: #0A112E;
        color: var(--white-100, #FFF);
        font-size: 18px;
      }

      .business1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }

      .business-text-container{
       padding: 60px 30px 30px 30px;
      }

      .bs-text-1 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.268px 0.489px rgba(0, 0, 0, 0.84);
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }

      .bs-text-2 {
        color: #F4F4F4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .business-world-container {
        margin: 5px 20px 0px 20px;
      }

      .business-world-container1 {
        margin: -15px 20px 0px 20px;
      }

      .business-world1-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: scroll;
      }

      .business-world1-container::-webkit-scrollbar{
        width: 0;
      }

      .business-logo-container {
        border-radius: 9px;
        border: 1px solid #1A3EBD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin: 15px;
      }

      .bs-name {
        color: #F4F4F4;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1px;
      }

      .bs-logo {
        width: 80px;
        height: 80px;
        margin: 20px;
      }

      .bs-button {
        display: flex;
        width: auto;
        height: auto;
        padding: 5px 30px;
        margin-right: 10px;
        margin-left: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 16px;
        background: #1A3EBD;
        border: none;
        margin: 20px;
        cursor: pointer;
        margin-bottom: 20px;
      }

      .see-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #0A112E;
      }

      .bt-see {
        display: flex;
        width: 131px;
        height: 27px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 50px;
        background: #1A3EBD;
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 5px 0px 100px 0px;
      }

      .price {
        background: #0A112E;
      }

      .price1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .price-text-container{
        margin: 100px 0px 40px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .pr-text-1 {
        color: #1A3EBD;
        font-size: 23.315px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.569px;
        margin-bottom: 20px;
      }

      .pr-text-2 {
        color: var(--white-100, #FFF);
        font-size: 28.797px;
        font-style: normal;
        font-weight: 400;
        line-height: 37.499px;
        margin-bottom: 10px;
      }

      .pr-text-3 {
        color: var(--white-100, #FFF);
        font-size: 12.499px;
        font-style: normal;
        font-weight: 400;
        line-height: 12.499px;
        margin-bottom: 20px;
      }

      .price-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin: 0px 30px;
        z-index: 2;
        flex-wrap: wrap;
      }

      .price-info-container1 {
        border-radius: 11px;
        border: 0.3px solid #1A3EBD;
        background: rgba(30, 30, 30, 0.61);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 40px;
        margin-right: 0%;
      }

      .price-info-container2 {
        border-radius: 11px;
        border: 0.3px solid #1A3EBD;
        background: rgba(30, 30, 30, 0.61);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 40px;
      }

      .price-info-container3 {
        border-radius: 11px;
        border: 0.3px solid #1A3EBD;
        background: rgba(30, 30, 30, 0.61);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 100px;
      }

      .basic-text {
        color: #A1A1A1;
        font-size: 17.362px;
        font-style: normal;
        font-weight: 600;
        line-height: 17.362px;
        margin: 20px 0px 0px 20px;
      }

      .price-text {
        color: var(--white-100, #FFF);
        font-size: 31.251px;
        font-style: normal;
        font-weight: 600;
        line-height: 31.251px; /* 100% */
        margin: 10px 0px 10px 20px;
      }

      .price-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 10px 20px 20px;
      }

      .price-logo {
        width: 10px;
        height: 9.722px;
        flex-shrink: 0;
        margin-right: 5px;
      }

      .info-text {
        color: var(--white-100, #FFF);
        font-size: 14.201px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .price-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0;
        padding: 10px 42%;
        gap: 10px;
        border-radius: var(--4, 4px);
        border: 1px solid #1A3EBD;
        margin: 20px 0px 50px 7%;
        text-align: center;
        cursor: pointer;
      }

      .bt-price-text {
        color: var(--white-100, #FFF);
        font-size: 14.201px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .cube1 {
        position: absolute;
        top: 85%;
        left: 5%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .cube2 {
        position: absolute;
        top: 1%;
        left: 92%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      .rating {
        background: #0A112E;
        height: auto;
      }

      .rating1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .rating-text-container{
        margin: 200px 0px 40px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .rt-text-1 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 21.348px;
        font-style: normal;
        font-weight: 900;
        line-height: 35.58px;
      }

      .rt-text-2 {
        color: var(--white-100, #FFF);
        text-align: center;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 35.58px;
      }

      .rating-world-container {
        margin: 10px 20px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
      }

      .rating-container1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px 10px;
      }

      .review-main-contianer-1 {
        display: none;
      }

      .review-container {

      }

      .review-text {
        color: var(--white-100, #FFF);
        font-size: 10.232px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.348px;
      }

      .reviewer-name {
        color: #040D36;
        font-size: 14.232px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.348px;
        margin-top: 20px;
      }

      .review-name {
        color: #7C7E84;
        font-size: 12.453px;
        font-style: italic;
        font-weight: 400;
        line-height: 17.79px;
        margin-top: 5px;
      }

      .review-logo {
        margin-top: 5px;
        width: 135.293px;
        height: 5.293px;
        margin-bottom: 30px;
      }

      .top-up {
        position: fixed;
        bottom: 200px;
        right: 40px;
        background-color: #1A3EBD;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 16px;
        cursor: pointer;
        border-radius: 5px;
        display: none; /* Hide by default */
        transition: opacity 0.3s ease-in-out; /* Smooth transition */
      }

      .top-up1 {
        position: fixed;
        bottom: 60px;
        right: 40px;
        background-color: #1A3EBD;
        color: white;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 5px;
        cursor: pointer;
        border-radius: 5px;
        display: none; /* Hide by default */
        transition: opacity 0.3s ease-in-out; /* Smooth transition */
        display: block;
      }

      /* Show the button when isVisible state is true */
      .top-up.show {
        display: block;
      }

      .arrow {
        margin-right: 5px; /* Space between arrow symbol and text */
      }

      .chatbot-img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }

      /* Hover animation */
      .top-up:hover {
        background-color: #1A3EBD; /* New background color */
        animation: bounce 0.5s ease infinite alternate; /* Bouncing animation */
      }

      .top-up1:hover {
        background-color: #1A3EBD; /* New background color */
        animation: bounce 0.5s ease infinite alternate; /* Bouncing animation */
      }

      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-5px);
        }
      }

      .footer {
        background: #0A112E;
        height: auto;
      }

      .footer1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .ft-text-container{
        margin: 300px 10px 40px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .footer-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .footer-logo{
        width: 18.786px;
        height: 24.77px;
        flex-shrink: 0;
        margin-right: 5px;
      }
  
      .footer-logo-name{
        color: var(--white-100, #FFF);
        font-size: 20.654px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.207px;
      }

      .copy-text {
        color: var(--White, var(--white-100, #FFF));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .footer-text-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px 100px 0px;
      }

      .footer-help {
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 10px;
      }

      .footer-link-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
}

 }