.navbar {
  height: 80px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  background: var(--white-100, #FFF);

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sidebar-btn {
      display: none;
    }

    .brand-name {
      color: #1B40C4;
      font-size: 24.654px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 20px;
        }

        .text-continer {
          display: flex;
          flex-direction: column;
        }

        .user-name {
          color: #242424;
          font-size: 16.142px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        
        .home-tag {
          color: #404040;
          font-size: 18.021px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.15px;
          cursor: pointer;
          text-decoration: none;
        }

        .user-id {
          color: #6C6262;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) { 
 
  .navbar {
    height: 80px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    background: var(--white-100, #FFF);
  
    .wrapper {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .sidebar {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        padding-top: 50px;
        border: none;
        box-shadow: none;
      }
  
      .sidebar-btn {
        background-color: #fff;
        border: none;
        box-shadow: none;
        cursor: pointer;
        padding-left: 5%;
        display: flex;
        padding-right: 0%;
        padding-bottom: 0%;
        padding-top: 1%;
      }
  
      .brand-name {
        color: #1B40C4;
        font-size: 16.654px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.207px;
        margin-left: 5px;
      }
  
      .items {
        display: flex;
        align-items: center;
  
        .item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;
  
          .icon {
            font-size: 12px;
          }
  
          .text-continer {
            display: flex;
            flex-direction: column;
          }
  
          .user-name {
            color: #242424;
            font-size: 12.142px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          
          .home-tag {
            color: #404040;
            font-size: 14.021px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.15px;
            cursor: pointer;
            text-decoration: none;
            display: none;
          }
  
          .user-id {
            color: #6C6262;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            
          }
  
          .avatar {
            width: 15px;
            height: 15px;
            border-radius: 50%;
          }
  
          .counter {
            width: 15px;
            height: 15px;
            background-color: red;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
    }
  }
  
}