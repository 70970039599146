.list {
    display: flex;
    width: 100%;
    .listContainer {
      display: flex;
      flex-direction: column;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 100px 20px 70px;
      background-color: #0A112E;
    }

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
    }

    .logo{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
    }

    .logo-name{
      color: var(--white-100, #FFF);
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .search {
      display: flex;
      width: 20%;
      height: 20px;
      padding: 10px 18px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 25px;
      border: 0.4px solid var(--white-100, #FFF);
      background: rgba(120, 120, 120, 0.40);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-left: 260px;
      margin-right: 20px;
      z-index: 1;
    }

    .search-box {
      background: none;
      border: none;
      width: 90%;
      height: 35px;
      box-shadow: none;
      outline: none;
    }
    
    .search-box:focus {
      border: 1px solid transparent;
    }    

    .search-icon-container {
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 50px;
      background: #FB591E;
    }

    .search-icon {
      width: 15.375px;
      height: 15.375px;
      flex-shrink: 0;
      fill: var(--white-100, #FFF);
    }  

    .login{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

      .button-container {
        display: flex;
        align-items: center;
      }

      .button1{
        color: #FB591E;
        font-size: 16.918px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.478px; /* 114.286% */
        background: none;
        margin-right: 30px;
        box-shadow: none;
        border: none;
        cursor: pointer;
        z-index: 1;
      }

      .button2{
        display: flex;
        padding: 10.416px 11.573px;
        justify-content: center;
        align-items: center;
        gap: 4.629px;
        border-radius: 8.629px;
        background: #FB591E;
        margin-left: 30px;
        color: #FAFAFB;
        font-size: 16.416px;
        font-style: normal;
        font-weight: 700;
        line-height: 13.888px;
        box-shadow: none;
        border: none;
        cursor: pointer;
        z-index: 1;
      }

      .home-text1 {
        color: var(--white-100, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: none;
        border: none;
        background: none;
        margin-left: auto;  /* Push the button to the right as much as possible */  /* Reset the right margin */
        cursor: pointer;
        margin-right: 50px;
        padding-bottom: 5px;
        z-index: 1;
      }  

      .home-text2 {
        color: var(--white-100, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: none;
        border: none;
        background: none;
        margin-left: auto;  /* Push the button to the right as much as possible */  /* Reset the right margin */
        cursor: pointer;
        margin-right: 50px;
        padding-bottom: 5px;
        z-index: 1;
      }  

      .start-line {
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);
        width: 80%;
        margin: auto;
      }

    .sidebar {
      display: block;
    }

  }

  @media only screen and (max-width: 768px) { 

    .list {
      display: flex;
      width: 100%;
      .listContainer {
        display: flex;
      flex-direction: column;
      }

      .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 20px;
      }
  
      .start-line {
        display: none;
      }
  
      .sidebar {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        padding-top: 50px;
        border: none;
        box-shadow: none;
      }
  
      .sidebar-btn {
        background-color: #0A112D;
        border: none;
        box-shadow: none;
        cursor: pointer;
        display: flex;
        padding-left: 25%;
      }
  
      .logo-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .logo{
        width: 18.786px;
        height: 24.77px;
        flex-shrink: 0;
        margin-right: 0;
      }
  
      .logo-name{
        color: var(--white-100, #FFF);
        font-size: 20.654px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.207px;
      }
  
      .search {
        display: flex;
        padding: 0px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 25px;
        border: none;
        background: none;
        box-shadow: none;
        margin-left: 25%;
        margin-right: 0px;
        z-index: 1;
      }
  
      .search-box {
        background: none;
        border: none;
        width: 90%;
        height: 35px;
        box-shadow: none;
        outline: none;
        display: none;
      }
      
      .search-box:focus {
        border: 1px solid transparent;
      }    
  
      .search-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 50px;
        background: #0A112D;
      }
  
      .search-icon {
        width: 22.375px;
        height: 22.375px;
        flex-shrink: 0;
        fill: var(--white-100, #FFF);
      }  
  
      .login{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
  
        .button-container {
          display: flex;
          align-items: center;
          display: none;
        }
  
        .button1{
          color: #FB591E;
          font-size: 16.918px;
          font-style: normal;
          font-weight: 500;
          line-height: 12.478px; /* 114.286% */
          background: none;
          margin-right: 0px;
          box-shadow: none;
          border: none;
          cursor: pointer;
          margin: 20px;
        }
  
        .button2{
          display: flex;
          padding: 10.416px 15.573px;
          justify-content: center;
          align-items: center;
          gap: 4.629px;
          border-radius: 6.629px;
          background: #FB591E;
          margin-left: 0px;
          color: #FAFAFB;
          font-size: 14.416px;
          font-style: normal;
          font-weight: 700;
          line-height: 13.888px;
          box-shadow: none;
          border: none;
          cursor: pointer;
          margin: 20px;
        }
  
        .home-text1 {
          color: var(--white-100, #FFF);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          box-shadow: none;
          border: none;
          background: none;
          margin-left: auto;
          cursor: pointer;
          margin-right: 70px;
          padding-bottom: 5px;
          margin: 20px;
        } 

    }

  }