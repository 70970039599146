.stepper-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 60px 20px 40px 40px;
}

.step-item {
  text-align: center;
  position: relative;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  margin-bottom: 8px;
  position: relative;
}

.step-item::before {
  content: '';
  position: absolute;
  top: 15px;
  left: calc(0% + 15px);
  height: 0.427px;
  width: 100%;
  background-color: #3D3D3D;
}

/* Remove the line from the last step */
.step-item:last-child::before {
  display: none;
}

.step.active {
  background-color: #2E54FF !important;
  color: #fff;
  border-color: #2E54FF;
}

.step.complete {
  background-color: #2E54FF;
  color: #fff;
  border-color: #2E54FF;
}

.text-gray-500 {
  color: #575757;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {

  .stepper-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 60px 20px 40px 40px;
  }
  
  .step-item {
    text-align: center;
    position: relative;
  }
  
  .step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
    margin-bottom: 8px;
    position: relative;
  }
  
  .step-item::before {
    content: '';
    position: absolute;
    top: 15px;
    left: calc(0% + 15px);
    height: 0.427px;
    width: 100%;
    background-color: #3D3D3D;
  }
  
  /* Remove the line from the last step */
  .step-item:last-child::before {
    display: none;
  }
  
  .step.active {
    background-color: #2E54FF !important;
    color: #fff;
    border-color: #2E54FF;
  }
  
  .step.complete {
    background-color: #2E54FF;
    color: #fff;
    border-color: #2E54FF;
  }
  
  .text-gray-500 {
    color: #575757;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: start;
  }

 }