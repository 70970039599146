.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
  // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 150px;
  margin-left: 20px;
  margin-right: 20px;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    .title {
      font-weight: bold;
      font-size: 20px;
      color: rgb(160, 160, 160);
      color: #000;
      font-style: normal;
      line-height: 118.023%; /* 21.996px */
      padding-top: 20px;
    }

    .counter {
      color: var(--black-100, #1C1C1C);
      font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 150% */
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid gray;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .arrow-image {
      width: 22px;
      height: 22px;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding-top: 20px;
      color: var(--black-100, #1C1C1C);
      font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */

      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }

    // .icon {
    //   font-size: 18px;
    //   padding: 5px;
    //   border-radius: 5px;
    //   align-self: flex-end;
    // }
  }
}

@media only screen and (max-width: 768px) {

  .widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
  
    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
  
      .title {
        font-weight: bold;
        font-size: 16px;
        color: rgb(160, 160, 160);
        color: #000;
        font-style: normal;
        line-height: 118.023%; /* 21.996px */
        padding-top: 20px;
      }
  
      .counter {
        color: var(--black-100, #1C1C1C);
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 150% */
      }
  
      .link {
        width: max-content;
        font-size: 12px;
        border-bottom: 1px solid gray;
      }
  
      .center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .arrow-image {
        width: 18px;
        height: 18px;
      }
  
      .percentage {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-top: 20px;
        color: var(--black-100, #1C1C1C);
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
  
        &.positive {
          color: green;
        }
        &.negative {
          color: red;
        }
      }
  
      // .icon {
      //   font-size: 18px;
      //   padding: 5px;
      //   border-radius: 5px;
      //   align-self: flex-end;
      // }
    }
  }

}