.chart {
  flex: 4;
  // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  color: gray;
  border-radius: 20px;
  padding-bottom: 20px;
  background: var(--Primary-Light, #F7F9FB);

  .title-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .title {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    margin-right: 20px;
  }

  .title1,
  .title2 {
    color: var(--black-40, rgba(28, 28, 28, 0.40));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-right: 20px;
  }

  .title3 {
    color: var(--black-20, rgba(28, 28, 28, 0.20));
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-right: 20px;
  }

  .current-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title4,
  .title5 {
    color: var(--black-100, #1C1C1C);
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 150% */
    margin-left: 5px;
    margin-right: 20px;
  }

  .chartGrid {
    stroke: rgb(228, 225, 225);
  }
}
