.barchart {
    flex: 4;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    color: gray;
    border-radius: 20px;
  
    .title {
      margin-bottom: 20px;
      color: #000;
      font-size: 18.637px;
      font-style: normal;
      font-weight: 600;
      line-height: 118.023%; /* 21.996px */
    }
  
    .chartGrid {
      stroke: rgb(228, 225, 225);
    }
  }
  