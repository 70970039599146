.Chat {
    background: #0A112E;
    color: #fff;
    font-size: 62.5%;
    min-height: 100vh;
    display: flex;

    .sideBar{
        flex: 0.31;
        border-right: 1px solid rgb(100, 100, 100);
    }
    
    .main {
        flex: auto;
    }

    .logo{
        margin-right: 2rem;
    }
    
    .brand {
        font-size: 1.5rem;
    }

    .upperSide {
        padding: 2.5rem;
    }

    .upperSideTop {
        display: flex;
        align-items: center;
        margin-bottom: 2.5rem;
    }

    .addBtn{
        height: 2rem;
        padding-right: 1rem;
    }
    
    .midBtn {
        background: #5A4BFF;
        border: none;
        color: white;
        padding: 1.5rem;
        font-size: 1.5rem;
        width: 25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 3rem;
        border-radius: 0.5rem;
    }

    .query{
        display: flex;
        align-items: center;
        background: transparent;
        color: rgba(222, 222, 222, 1);
        padding: 1.5rem;
        width: 25rem;
        margin: 1rem auto;
        border-radius: 0.5rem;
        border: 1px solid rgba(98, 98, 98, 1);
    }

    .query img {
        margin-right: 2rem;
        object-fit: cover;
        height: 1.75rem;
    }

    .main{
        min-height: calc(100vh - 14rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3rem 10rem 6rem 10rem;
        margin-bottom: 2.5rem;
        z-index: 1;
    }

    .chats {
        overflow: hidden;
        overflow-y: scroll;
        scroll-behavior: smooth;
        width: 100%;
        max-width: 70rem;
        height: calc(100vh - 17rem);
        z-index: 1;
    }

    .chats::-webkit-scrollbar {
        display: none; /* Width of the scrollbar */
    }
    
    // .chats::-webkit-scrollbar-track {
    //     background-color: #F3F6FB; /* Color of the track */
    // }

    .chat {
        margin: 1rem;
        padding: 2rem 3rem;
        font-size: 1.2rem;
        display: flex;
        align-items: flex-start;
        text-align: justify;
        z-index: 1;
    }

    .chatImg {
        object-fit: cover;
        width: 3rem;
        margin-right: 2rem;
        // margin-left: -1rem;
        // background-color: rgb(174, 174, 179);
        // border-radius: 0.5rem;
        // padding: 1rem 0.5rem 1rem 0.5rem;
    }

    .bot {
        background: rgba(28, 30, 58, 1);
        width: fit-content;
        border-radius: 0.5rem;
    }

    .chatImg1 {
        object-fit: cover;
        margin-right: 3.9rem;
    }

    .chatFooter {
        margin-top: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inp {
        padding: 0.5rem;
        background: rgba(28, 30, 58, 1);
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
        width: 100%;
    }

    .inp input {
        width: 100%;
        outline: none;
        padding: 1.25rem;
        color: #fff;
    }

    .inp input, .send {
        background: transparent;
        border: none;
    }

    .chatFooter p {
        margin: 2rem 0;
    }

    .txt {
        color: #fff;
    }

  }

  @media only screen and (max-width: 768px) {

    .Chat {
        background: #0A112E;
        color: #fff;
        font-size: 62.5%;
        min-height: auto;
        display: flex;
    
        .sideBar{
            display: none;
            // border-right: 1px solid rgb(100, 100, 100);
        }
        
        .main {
            flex: auto;
        }
    
        .logo{
            margin-right: 2rem;
        }
        
        .brand {
            font-size: 1.5rem;
        }
    
        .upperSide {
            padding: 2.5rem;
        }
    
        .upperSideTop {
            display: flex;
            align-items: center;
            margin-bottom: 2.5rem;
        }
    
        .addBtn{
            height: 2rem;
            padding-right: 1rem;
        }
        
        .midBtn {
            background: #5A4BFF;
            border: none;
            color: white;
            padding: 1.5rem;
            font-size: 1.5rem;
            width: 25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-bottom: 3rem;
            border-radius: 0.5rem;
        }
    
        .query{
            display: flex;
            align-items: center;
            background: transparent;
            color: rgba(222, 222, 222, 1);
            padding: 1.5rem;
            width: auto;
            margin: 1rem auto;
            border-radius: 0.5rem;
            border: 1px solid rgba(98, 98, 98, 1);
        }
    
        .query img {
            margin-right: 2rem;
            object-fit: cover;
            height: 1.75rem;
        }
    
        .main{
            min-height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            margin-bottom: 0;
            z-index: 0;
        }
    
        .chats {
            overflow: hidden;
            overflow-y: scroll;
            scroll-behavior: smooth;
            width: auto;
            max-width: auto;
            height: 80vh;
            overflow-x: hidden;
            z-index: 1;
        }
    
        .chats::-webkit-scrollbar-thumb {
            background-color: #0A112E;
            outline: 1px solid #0A112E;
          }
    
        .chat {
            margin: 1rem;
            padding: 2rem 2rem;
            font-size: 1rem;
            display: flex;
            align-items: flex-start;
            text-align: justify;
        }
    
        .chatImg {
            object-fit: cover;
            width: 3rem;
            margin-right: 2rem;
        }
    
        .bot {
            background: rgba(28, 30, 58, 1);
            width: fit-content;
            border-radius: 0.5rem;
        }
    
        .chatImg1 {
            object-fit: cover;
            margin-right: 3.9rem;
        }
    
        .chatFooter {
            margin-top: auto;
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    
        .inp {
            padding: 0.5rem;
            background: rgba(28, 30, 58, 1);
            display: flex;
            align-items: center;
            border-radius: 0.5rem;
            width: 100%;
            margin-top: 1rem;
        }
    
        .inp input {
            width: 100%;
            outline: none;
            padding: 1.25rem;
            color: #fff;
        }
    
        .inp input, .send {
            background: transparent;
            border: none;
        }
    
        .chatFooter p {
            margin: 2rem 0;
        }
    
        .txt {
            color: #fff;
            word-wrap: break-word;
        }
    
      }
  }