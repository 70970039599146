.featured {
  flex: 2;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  height: 50%;
  border-radius: 20px;
  min-width: 400px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray;

    .title {
      color: #000;
      font-size: 18.637px;
      font-style: normal;
      font-weight: 600;
      line-height: 118.023%; /* 21.996px */
      margin-top: 10px;
    }
  }

  .top1 {
    display: flex;
    align-items: center;
    color: gray;

    .title1 {
      color: #908C8C;
      font-size: 16.312px;
      font-style: normal;
      font-weight: 600;
      line-height: 118.023%; /* 15.711px */
      margin-top: 20px;
      position: relative;
      margin-right: 20px;

      .dot1 {
        width: 12px; // Adjust the size of the dot as needed
        height: 12px;
        background-color: #FFB800; // Replace with the appropriate color for each title
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px; // Adjust the distance between the dot and the title
      }

      .dot2 {
        width: 12px; // Adjust the size of the dot as needed
        height: 12px;
        background-color: #43A921; // Replace with the appropriate color for each title
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px; // Adjust the distance between the dot and the title
      }

      .dot3 {
        width: 12px; // Adjust the size of the dot as needed
        height: 12px;
        background-color: #3E35F0; // Replace with the appropriate color for each title
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px; // Adjust the distance between the dot and the title
      }
    }
  }

  .bottom {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;

    p {
      margin-top: 20px;
    }

    .featuredChart1 {
      width: 100%;
      height: 10px;
      border: none;
      appearance: none;
      border-radius: 20px;
      margin-bottom: 20px;

      progress {
        width: 100%;
        height: 100%;
        background-color: #FEF6D1; // Updated background color
        border-radius: 20px;

        &::-webkit-progress-bar {
          background-color: #FEF6D1; // Updated background color
          border-radius: 20px;
        }

        &::-webkit-progress-value {
          background-color: #FEB005; // Updated progress color
          border-radius: 20px;
        }

        &::-moz-progress-bar {
          background-color: #FEB005; // Updated progress color
          border-radius: 20px;
        }
      }
    }

    .featuredChart2 {
      width: 100%;
      height: 10px;
      border: none;
      appearance: none;
      border-radius: 20px;
      margin-bottom: 20px;

      progress {
        width: 100%;
        height: 100%;
        background-color: #D3FFD3; // Updated background color
        border-radius: 20px;

        &::-webkit-progress-bar {
          background-color: #D3FFD3; // Updated background color
          border-radius: 20px;
        }

        &::-webkit-progress-value {
          background-color: #31B639; // Updated progress color
          border-radius: 20px;
        }

        &::-moz-progress-bar {
          background-color: #31B639; // Updated progress color
          border-radius: 20px;
        }
      }
    }

    .featuredChart3 {
      width: 100%;
      height: 10px;
      border: none;
      appearance: none;
      border-radius: 20px;
      margin-bottom: 20px;

      progress {
        width: 100%;
        height: 100%;
        background-color: #D9D7F7; // Updated background color
        border-radius: 20px;

        &::-webkit-progress-bar {
          background-color: #D9D7F7; // Updated background color
          border-radius: 20px;
        }

        &::-webkit-progress-value {
          background-color: #4036F6; // Updated progress color
          border-radius: 20px;
        }

        &::-moz-progress-bar {
          background-color: #4036F6; // Updated progress color
          border-radius: 20px;
        }
      }
    }

    .title {
      font-weight: 500;
      color: gray;
    }

    .amount {
      font-size: 30px;
    }

    .desc {
      font-weight: 300;
      font-size: 12px;
      color: gray;
      text-align: center;
    }

    .summary {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        text-align: center;

        .itemTitle {
          font-size: 14px;
          color: gray;
        }

        .itemResult {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 14px;

          &.positive {
            color: green;
          }

          &.negative {
            color: red;
          }
        }
      }
    }
  }
}