.home {
  display: flex;
  background: #F3F6FB;

  .homeContainer {
    flex: 6;

    .dash-image-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 30px;
      background-color: #1B40C4;
      margin-right: 40px;
      margin-left: 40px;
      border-radius: 9px;
      padding: 0px 50px;
      z-index: 1;
    }

    .dash-text-container {
      margin-left: 10px;
    }

    .dash-text1 {
      color: var(--white-100, #FFF);
      font-size: 31.6px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.316px;
    }

    .dash-text2 {
      color: var(--white-100, #FFF);
      font-size: 15.048px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.15px;
      margin-top: 10px;
    }

    .dash-button {
      display: flex;
      width: 113px;
      height: 28.462px;
      padding: 4.248px;
      justify-content: center;
      align-items: center;
      gap: 4.248px;
      flex-shrink: 0;
      border-radius: 2.124px;
      background: var(--white-100, #FFF);
      color: #1B40C4;
      font-size: 10.762px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.108px;
      border: none;
      cursor: pointer;
      margin-top: 30px;
      z-index: 1;
    }
    
    .dash-image{

    }

    .widgets,
    .charts,
    .state {
      display: flex;
      padding: 20px;
      gap: 20px;
      flex-wrap: wrap;
    }

    .charts {
      padding: 5px 40px;
    }

    .state {
      justify-content: center;
      align-items: center;
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;
      border-radius: 20px;

      .row {
        display: flex;
        justify-content: space-between;
      }

      .listTitle {
        margin-bottom: 15px;
        color: #000;
        font-size: 18.637px;
        font-style: normal;
        font-weight: 600;
        line-height: 118.023%; /* 21.996px */
      }
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    border-radius: 6.606px;
    background: var(--white-100, #FFF);
    position: relative;
  }

  .modal-head {
    width: 397.078px;
    height: 76.333px;
    background: #0A112E;
    border-top-right-radius: 6.606px;
    border-top-left-radius: 6.606px;
  }

  .modal-head-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-100, #FFF);
    font-size: 16.701px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.551px;
    padding-top: 30px;
  }

  .modal-body {
    border-radius: 6.606px;
    background: var(--white-100, #FFF);
  }
  
  .input-modal {
    display: flex;
    width: 284.046px;
    height: 35.231px;
    padding: 8.808px 11.744px;
    align-items: center;
    gap: 5.872px;
    border-radius: 5.872px;
    border: 0.734px solid var(--Neutral-2, #D7D7DB);
    background: var(--white-100, #FFF);
    margin: 10px;
    margin-left: 40px;
    margin-top: 50px;
  }

  .radio-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .radio-text {
    color: #151515;
    font-size: 11.744px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.615px;
    margin-right: 10px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;
  }

  .button1 {
    display: flex;
    padding: 6.014px 12.014px 6.014px 12.028px;
    justify-content: center;
    align-items: center;
    gap: 6.014px;
    border-radius: 4.21px;
    background: #1B40C4;
    color: var(--white-100, #FFF);
    font-size: 16.422px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.124px;
    border: none;
    cursor: pointer;
    margin-right: 20px;
  }

  .button2 {
    display: flex;
    padding: 6.014px 20.014px 6.014px 20.028px;
    justify-content: center;
    align-items: center;
    gap: 6.014px;
    border-radius: 4.21px;
    background: #D3D3D3;
    color: #787878;
    font-size: 16.422px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.124px;
    border: none;
    cursor: pointer;
    margin-left: 20px;
  }

  .info-text {
    color: #323232;
    text-align: center;
    font-size: 14.428px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.074px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }

}

/* Media query for smaller screens (e.g., mobile devices) */
@media only screen and (max-width: 768px) {

  .home {
    display: flex;
    background: #fff;

    .sidebar {
      display: none; /* Hide the sidebar */
    }
  
    .homeContainer {
      flex: 6;
  
      .dash-image-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 30px;
        background-color: #1B40C4;
        margin-right: 40px;
        margin-left: 40px;
        border-radius: 9px;
        padding: 0px 50px;
        display: none;
      }
  
      .dash-text-container {
        margin-left: 10px;
      }
  
      .dash-text1 {
        color: var(--white-100, #FFF);
        font-size: 31.6px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.316px;
      }
  
      .dash-text2 {
        color: var(--white-100, #FFF);
        font-size: 15.048px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.15px;
        margin-top: 10px;
      }
  
      .dash-button {
        display: flex;
        width: 113px;
        height: 28.462px;
        padding: 4.248px;
        justify-content: center;
        align-items: center;
        gap: 4.248px;
        flex-shrink: 0;
        border-radius: 2.124px;
        background: var(--white-100, #FFF);
        color: #1B40C4;
        font-size: 10.762px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.108px;
        border: none;
        cursor: pointer;
        margin-top: 30px;
      }
      
      .dash-image{
  
      }
  
      .widgets,
      .charts,
      .state {
        display: flex;
        padding: 20px;
        gap: 20px;
        flex-wrap: wrap;
      }
  
      .charts {
        padding: 5px 40px;
      }
  
      .state {
        justify-content: center;
        align-items: center;
      }
  
      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 20px;
        border-radius: 20px;
  
        .row {
          display: flex;
          justify-content: space-between;
        }
  
        .listTitle {
          margin-bottom: 15px;
          color: #000;
          font-size: 18.637px;
          font-style: normal;
          font-weight: 600;
          line-height: 118.023%; /* 21.996px */
        }
      }
    }
  
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: start;
      padding-top: 20%;
    }
    
    .modal {
      border-radius: 6.606px;
      background: var(--white-100, #FFF);
      position: relative;
    }
  
    .modal-head {
      width: 300.078px;
      height: 76.333px;
      background: #0A112E;
      border-top-right-radius: 6.606px;
      border-top-left-radius: 6.606px;
    }
  
    .modal-head-text {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white-100, #FFF);
      font-size: 16.701px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.551px;
      padding-top: 30px;
    }
  
    .modal-body {
      border-radius: 6.606px;
      background: var(--white-100, #FFF);
    }
    
    .input-modal {
      display: flex;
      width: 65%;
      height: auto;
      padding: 8.808px 11.744px;
      align-items: center;
      gap: 5.872px;
      border-radius: 5.872px;
      border: 0.734px solid var(--Neutral-2, #D7D7DB);
      background: var(--white-100, #FFF);
      margin: 10px;
      margin-left: 40px;
      margin-top: 50px;
    }
  
    .radio-group {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .radio-text {
      color: #151515;
      font-size: 11.744px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.615px;
      margin-right: 10px;
    }
  
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 40px;
    }
  
    .button1 {
      display: flex;
      padding: 6.014px 12.014px 6.014px 12.028px;
      justify-content: center;
      align-items: center;
      gap: 6.014px;
      border-radius: 4.21px;
      background: #1B40C4;
      color: var(--white-100, #FFF);
      font-size: 16.422px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.124px;
      border: none;
      cursor: pointer;
      margin-right: 20px;
    }
  
    .button2 {
      display: flex;
      padding: 6.014px 20.014px 6.014px 20.028px;
      justify-content: center;
      align-items: center;
      gap: 6.014px;
      border-radius: 4.21px;
      background: #D3D3D3;
      color: #787878;
      font-size: 16.422px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.124px;
      border: none;
      cursor: pointer;
      margin-left: 20px;
    }
  
    .info-text {
      color: #323232;
      text-align: center;
      font-size: 12.428px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.074px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 20px;
      color: #333;
    }
  
  }

 }