.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;
        border-radius: 20px;

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 20px;
          font-size: 18px;
          color: #fff;
          background-color: #43A921;
          cursor: pointer;
          border-radius: 0px 20px 0px 5px;
        }

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
      border-radius: 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {

  .single {
    display: flex;
    width: 100%;

    .sidebar {
      display: none; /* Hide the sidebar */
    }

    .singleContainer {
      flex: 6;
  
      .top {
        padding: 20px;
        display: flex;
        gap: 20px;
  
        .left {
          flex: 1;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          padding: 20px;
          position: relative;
          border-radius: 20px;
  
          .editButton {
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
            font-size: 18px;
            color: #fff;
            background-color: #43A921;
            cursor: pointer;
            border-radius: 0px 20px 0px 5px;
          }
  
          .item {
            display: flex;
            gap: 20px;
  
            .itemImg {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
            }
  
            .details {
              .itemTitle {
                margin-bottom: 10px;
                color: #555;
              }
  
              .detailItem {
                margin-bottom: 10px;
                font-size: 14px;
  
                .itemKey {
                  font-weight: bold;
                  color: gray;
                  margin-right: 5px;
                }
  
                .itemValue {
                  font-weight: 300;
                }
              }
            }
          }
        }
  
        .right {
          flex: 2;
        }
      }
  
      .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 10px 20px;
        border-radius: 20px;
      }
  
      .title {
        font-size: 16px;
        color: lightgray;
        margin-bottom: 20px;
      }
    }
  }

 }