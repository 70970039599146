.container-signup{
}

.header{
  display: flex;
  height: 32px;
  
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  flex-shrink: 0;
  background: var(--white-100, #FFF);
  box-shadow: 0px 4px 24.9px 0px rgba(0, 0, 0, 0.45);
}

.sidebar-btn {
  display: none;
}

.logo-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.password-toggle{
  position: absolute;
  left:83%; /* Adjust as needed */
  cursor: pointer;
  user-select: none;
  bottom: 34%;
}

.password-toggle2{
  position: absolute;
  left:83%;  /* Adjust as needed */
  cursor: pointer;
  user-select: none;
  bottom: 34%;
}

.eye-logo{
  width: 20px;
  height: 20px;
}

.logo1{
  width: 18.786px;
  height: 24.77px;
  flex-shrink: 0;
  margin-right: 5px;
}

.logo2 {
  display: none;
}

.logo-name{
  color: #1B40C4;
  font-size: 20.654px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.207px;
}

.payment-image-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0C132F;
  padding: 0px 30px;
}

.payment-image-text {
  color: var(--white-100, #FFF);
  font-size: 31.762px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  word-spacing: 2px;
}

.payment-image-text .highlighted {
  color: var(--white-100, #F15A27);
  font-size: 31.762px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.payment-image{
  flex-shrink: 0;
  margin-right: 40px;
}

.container2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .login-img-container {
      background-color: #fff;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .login-bg-img{
      width: 40dvw;
      flex-shrink: 0;
    }

    .login{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #FFF;
      margin-top: 100px;
    }

    .input-header {
      color: var(--Neutral-2, #4A4B4F);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-left: 10px;
      line-height: 21.364px;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .text1 {
      color: #000;
      font-size: 22.137px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.221px;
    }

    .text2 {
      color: #7C7E84;
      font-size: 12.35px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.524px;
    }

    .payment-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .payment-options {
      border-radius: var(--16, 16px);
      background: var(--white-100, #FFF);
      box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
      margin-bottom: 20px;
      overflow: hidden;
    }

    .payment-options1 {
      border-radius: var(--16, 16px);
      background: var(--white-100, #FFF);
      box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
      margin-bottom: 20px;
      overflow: hidden;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .best-value {
      display: inline-flex;
      padding-left: 20px;
      justify-content: flex-end;
      align-items: center;
      gap: 96.21px;
      background: #E1E9FC;
      margin-top: 20px;
      position: relative; /* Required for absolute positioning of ::after pseudo-element */
    }
    
    .best-value::after {
      content: "";
      width: 25px;
      height: 25px;
      transform: rotate(-45deg);
      background: var(--white-100, #FFF);
      position: absolute;
      top: 0;
      right: -10px; /* Adjust the value as needed */
    }    

    .best-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #336AEA;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px; /* 192.308% */
      padding-right: 40px;
    }

    .row-payment {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-right: 20px;
      margin-left: 10px;
    }

    .back-button1{
      color: #2E54FF;
      font-size: 22px;
      border: none;
      cursor: pointer;
      margin-top: 15px;
      margin-right: 15px;
      background: #fff;
      display: flex;
    }

    .back-button2{
      color: #2E54FF;
      font-size: 22px;
      border: none;
      cursor: pointer;
      margin-top: 15px;
      margin-right: 15px;
      background: #fff;
      display: none;
    }

    .subscribe-button {
      display: flex;
      width: 149.19px;
      height: 44px;
      padding: 12px 19.631px 13px 20px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 5px;
      background: #336AEA;
      margin-bottom: 20px;
      border: none;
      cursor: pointer;
    }

    .payment-text {
      padding: 10px 40px 15px 0px;
    }

    .subscribe-text {
      color: var(--white-100, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 293.333% */
    }

    .year-text {
      color: #121212;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 160% */
      text-transform: uppercase;
      margin-left: 10px;
      margin-top: 10px;
    }

    .money-row{
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      margin-top: 10px;
    }

    .price {
      color: #121212;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }

    .month-text {
      color: #424242;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-top: 3px;
      margin-left: 3px;
    }

    .terms-text1 {
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-left: 10px;
      margin-top: 10px;
    }

    .terms-text2 {
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .custom-color {
      color: #121212;
      margin-left: 0px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 150% */
    }

    form{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px; /* Adjust the gap as needed */
      z-index: 1;

      .password-container{
        width: 100%;
        position: relative;
      }

      input{
        margin: 10px;
        display: flex;
        width: 400px;
        height: 20px;
        padding: 17.026px 22.701px;
        align-items: center;
        gap: 11.35px;
        border-radius: 11.35px;
        border: 1.419px solid var(--neutral-2, #D7D7DB);
        background: var(--White, #FFF);
        color: var(--Body-text, #4F5672);
      }

      select {
        margin: 10px;
        display: flex;
        width: 450px;
        height: 60px;
        padding: 17.026px 22.701px;
        align-items: center;
        gap: 11.35px;
        border-radius: 11.35px;
        border: 1.419px solid var(--neutral-2, #D7D7DB);
        background: var(--White, #FFF);
        color: var(--Body-text, #4F5672);
      }

      .image-container {
        display: flex;
        width: 445px;
        padding: 5.639px 0px 5.361px 0px;
        justify-content: center;
        align-items: center;
        border-radius: 4.149px;
        border: 0.83px dashed #000;
        height: 40px;
        margin-top: 20px;
        margin-left: 10px;
      }

      .icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
      }

      .login-img {
        margin-left: 5px;
      }

      span{
        font-size: 16px;
        color: #2E54FF;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 20px;
      z-index: 1;
    }

    .button-container1 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 20px;
      z-index: 1;
    }

    .button1{
      font-size: 16px;
      border: none;
      border-radius: 10px;
      background: #E5E5E5;
      color: #525252;
      font-weight: bold;
      cursor: pointer;
      flex-shrink: 0;
      padding: 10px 30px 10px 30px;
    }

    .button2{
      font-size: 16px;
      border: none;
      border-radius: 10px;
      background: #2E54FF;
      color: white;
      font-weight: bold;
      cursor: pointer;
      flex-shrink: 0;
      padding: 15px 200px 15px 200px;
      width: 100%;
      margin-top: 20px;
    }

    .text3 {
      color: #555;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 40px;
      cursor: pointer;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      }

      .text3 .underline {
        color: #1B40C4;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 4px;
        margin-left: 4px;
      }      

      .text4 {
        color: #4A4B4F;
        font-size: 17.519px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.364px;
        margin-top: 60px;
        margin-bottom: 20px;
        }

      .login-link {
        color: #2E54FF;
        cursor: pointer;
      }
}

/* Media query for smaller screens (e.g., mobile devices) */
@media only screen and (max-width: 768px) {

  .container-signup{
  }
  
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
  }
  
  .sidebar {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding-top: 50px;
    border: none;
    box-shadow: none;
  }

  .sidebar-btn {
    background-color: #1B40C4;
    border: none;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    padding-right: 10%;
    z-index: 1;
  }

  .logo1{
    width: 18.786px;
    height: 24.77px;
    flex-shrink: 0;
    margin-right: 5px;
    display: none;
  }

  .button5{
    color: #FB591E;
    font-size: 16.918px;
    font-style: normal;
    font-weight: 500;
    line-height: 12.478px; /* 114.286% */
    background: none;
    margin-right: 0px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    margin: 20px;
  }

  .button6{
    display: flex;
    padding: 10.416px 15.573px;
    justify-content: center;
    align-items: center;
    gap: 4.629px;
    border-radius: 6.629px;
    background: #FB591E;
    margin-left: 0px;
    color: #FAFAFB;
    font-size: 14.416px;
    font-style: normal;
    font-weight: 700;
    line-height: 13.888px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    margin: 20px;
  }

  .home-text {
    color: var(--white-100, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-shadow: none;
    border: none;
    background: none;
    margin-left: auto;
    cursor: pointer;
    margin-right: 70px;
    padding-bottom: 5px;
    margin: 20px;
  }  

  .logo2 {
    width: 18.786px;
    height: 24.77px;
    flex-shrink: 0;
    margin-right: 5px;
    display: flex;
  }
  
  .logo-name{
    color: #1B40C4;
    font-size: 20.654px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.207px;
  }
  
  .payment-image-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #0C132F;
    padding: 20px 30px;
  }
  
  .payment-image-text {
    color: var(--white-100, #FFF);
    font-size: 26.762px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    word-spacing: 2px;
  }
  
  .payment-image-text .highlighted {
    color: var(--white-100, #F15A27);
    font-size: 26.762px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .payment-image{
    flex-shrink: 0;
    margin-right: 40px;
    display: none;
  }

  .header{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    flex-shrink: 0;
    background: #1B40C4;
    box-shadow: 0px 4px 24.9px 0px rgba(0, 0, 0, 0.45);
  }

  .logo-name{
    color: var(--white-100, #FFF);
    font-size: 20.654px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.207px;
    margin-left: 10px;
  }

  .container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #FFF;
    }

    .input-header {
      color: var(--Neutral-2, #4A4B4F);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-left: 10px;
      line-height: 21.364px;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .text1 {
      color: #000;
      font-size: 18.681px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.287px;
    }

    .text2 {
      color: #7C7E84;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .payment-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;
    }

    .payment-options {
      border-radius: var(--16, 16px);
      background: var(--white-100, #FFF);
      box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
      margin-bottom: 40px;
      overflow: hidden;
    }

    .payment-options1 {
      border-radius: var(--16, 16px);
      background: var(--white-100, #FFF);
      box-shadow: 0px 5px 10px 0px rgba(66, 66, 66, 0.15), 0px 1px 4px 0px rgba(66, 66, 66, 0.10);
      margin-bottom: 20px;
      overflow: hidden;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .best-value {
      display: inline-flex;
      padding-left: 20px;
      justify-content: flex-end;
      align-items: center;
      gap: 96.21px;
      background: #E1E9FC;
      margin-top: 20px;
      position: relative; /* Required for absolute positioning of ::after pseudo-element */
    }
    
    .best-value::after {
      content: "";
      width: 25px;
      height: 25px;
      transform: rotate(-45deg);
      background: var(--white-100, #FFF);
      position: absolute;
      top: 0;
      right: -10px; /* Adjust the value as needed */
    }    

    .best-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #336AEA;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px; /* 192.308% */
    }

    .payment-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px;
    }

    .row-payment {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .back-button1{
      color: #2E54FF;
      font-size: 22px;
      border: none;
      cursor: pointer;
      margin-top: 15px;
      margin-right: 15px;
      background: #fff;
      display: none;
    }
  
    .back-button2{
      color: #2E54FF;
      font-size: 22px;
      border: none;
      cursor: pointer;
      margin-top: 25px;
      margin-right: 15px;
      background: #fff;
      display: flex;
      padding-left: 20px;
    }

    .subscribe-button {
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 5px;
      background: #336AEA;
      margin-bottom: 40px;
      border: none;
      cursor: pointer;
    }

    .subscribe-text {
      color: var(--white-100, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
    }

    .year-text {
      color: #121212;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 10px;
      margin-top: 10px;
      font-size: 13.864px;
      font-style: normal;
      font-weight: 700;
      line-height: 22.183px;
    }

    .money-row{
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      margin-top: 10px;
    }

    .price {
      color: #121212;
      text-transform: uppercase;
      font-size: 29.067px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.8px;
    }

    .month-text {
      color: #424242;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-top: 3px;
      margin-left: 3px;
    }

    .terms-text1 {
      color: #777;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-left: 10px;
      margin-top: 15px;
    }

    .terms-text2 {
      color: #777;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .custom-color {
      color: #121212;
      margin-left: 0px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 150% */
    }

    .password-toggle{
      position: absolute;
      left:100%; /* Adjust as needed */
      cursor: pointer;
      user-select: none;
      bottom: 34%;
    }

    .password-toggle2{
      position: absolute;
      left:100%; /* Adjust as needed */
      cursor: pointer;
      user-select: none;
      bottom: 34%;
    }

    form{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .password-container{
        margin-right: 65px;
        width: 100%;
        position: relative;
      }

      input{
        margin: 10px;
        padding: 17.026px 22.701px;
        width: 100%;
        gap: 11.35px;
        border-radius: 11.35px;
        border: 1.419px solid var(--neutral-2, #D7D7DB);
        background: var(--White, #FFF);
        color: var(--Body-text, #4F5672);
      }

      select {
        margin: 10px;
        padding: 17.026px 0px;
        width: 113%;
        gap: 11.35px;
        border-radius: 11.35px;
        border: 1.419px solid var(--neutral-2, #D7D7DB);
        background: var(--White, #FFF);
        color: var(--Body-text, #4F5672);
      }

      .image-container {
        display: flex;
        width: 100%;
        padding: 5.639px 20px 5.361px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 4.149px;
        border: 0.83px dashed #000;
        height: 40px;
        margin-top: 20px;
      }

      .icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
      }

      .login-img {
        margin-left: 5px;
      }

      span{
        font-size: 16px;
        color: #2E54FF;
        margin-top: 10px;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .button-container1 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 5px;
    }

    .button1{
      font-size: 16px;
      border: none;
      border-radius: 10px;
      background: #E5E5E5;
      color: #525252;
      font-weight: bold;
      cursor: pointer;
      flex-shrink: 0;
      padding: 10px 30px 10px 30px;
    }

    .button2{
      font-size: 16px;
      border: none;
      border-radius: 10px;
      background: #2E54FF;
      color: white;
      font-weight: bold;
      cursor: pointer;
      margin-top: 20px;
      padding: 20px 150px;
    }

    .stepper {
      margin: 15px;
    }

    .text3 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #555;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 40px;
      z-index: 1;
      }

      .text3 .underline {
        color: #1B40C4;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 0px;
        margin-left: 0px;
      }      

      .text4 {
        color: #4A4B4F;
        font-size: 17.519px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.364px;
        margin-top: 40px;
        margin-bottom: 20px;
        }

      .login-link {
        color: #2E54FF;
        cursor: pointer;
      }

  }

}