.search-container{
    background: #0A112E;
    
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 100px 20px 70px;
    }

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .logo{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
    }

    .logo-name{
      color: var(--white-100, #FFF);
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .search {
      display: flex;
      width: 20%;
      height: 20px;
      padding: 10px 18px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 25px;
      border: 0.4px solid var(--white-100, #FFF);
      background: rgba(120, 120, 120, 0.40);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-left: 260px;
      margin-right: 20px;
      z-index: 1;
    }

    .search-box {
      background: none;
      border: none;
      width: 90%;
      height: 35px;
      box-shadow: none;
      outline: none;
      color: #FFFF;
    }
    
    .search-box:focus {
      border: 1px solid transparent;
    }    

    .search-icon-container {
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 50px;
      background: #FB591E;
    }

    .search-icon {
      width: 15.375px;
      height: 15.375px;
      flex-shrink: 0;
      fill: var(--white-100, #FFF);
    }  

    .home-text2 {
      color: var(--white-100, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      box-shadow: none;
      border: none;
      background: none;
      margin-left: auto;  /* Push the button to the right as much as possible */  /* Reset the right margin */
      cursor: pointer;
      margin-right: 50px;
      padding-bottom: 5px;
      z-index: 1;
    }  

    .login{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

      .button-container {
        display: flex;
        align-items: center;
      }

      .button1{
        color: #FB591E;
        font-size: 16.918px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.478px; /* 114.286% */
        background: none;
        margin-right: 30px;
        box-shadow: none;
        border: none;
        cursor: pointer;
      }

      .button2{
        display: flex;
        padding: 10.416px 11.573px;
        justify-content: center;
        align-items: center;
        gap: 4.629px;
        border-radius: 8.629px;
        background: #FB591E;
        margin-left: 30px;
        color: #FAFAFB;
        font-size: 16.416px;
        font-style: normal;
        font-weight: 700;
        line-height: 13.888px;
        box-shadow: none;
        border: none;
        cursor: pointer;
      }

      .home-text {
        color: var(--white-100, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: none;
        border: none;
        background: none;
        margin-left: auto;
        cursor: pointer;
        margin-right: 50px;
        padding-bottom: 5px;
      }  

      .category-container {
        margin: 10px 100px 10px 100px;
        display: flex;
        align-items: flex-start;
        justify-content: start;
        flex-wrap: wrap;
      }

      .arrow-bro{
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);
        width: 90%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
      }

      .category-bt1 {
        background: #1735A0;
        display: flex;
        padding: 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        color: #CACACA;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.15px;
        border: none;
        cursor: pointer;
        margin-right: 30px;
        white-space: nowrap;
      }

      .category-bt2 {
        display: flex;
        padding: 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        background: rgba(159, 159, 161, 0.45);
        color: #CACACA;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.15px;
        border: none;
        cursor: pointer;
        margin-right: 30px;
        white-space: nowrap;
      }

      .business {
        background: #0A112E;
        overflow-x: hidden;
        height: auto;
      }

      .business::-webkit-scrollbar {
        width: 0.5em;
      }
      
      .business::-webkit-scrollbar-thumb {
        background-color: #0A112E;
        outline: 1px solid #0A112E;
      }

      .business1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }

      .business-text-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 60px 100px 40px 100px;
      }

      .bs-text-1 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.268px 0.489px rgba(0, 0, 0, 0.84);
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }

      .bs-text-2 {
        color: #F4F4F4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .business-world-container {
        margin: 20px 100px 0px 100px;
      }

      .business-world1-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .no-business{
        padding-left: 100px;
        padding-right: 100px;
        background-color: #0A112E;
        color: var(--white-100, #FFF);
        font-size: 26px;
        padding-bottom: 5%;
      }

      .business-logo-container {
        border-radius: 9px;
        border: 1px solid #1A3EBD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-right: 69px;
        margin-bottom: 60px;
      }

      .bs-name {
        color: #F4F4F4;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0px 10px 10px 10px;
        max-width: 100px; /* Adjust as needed */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // word-wrap: break-word;
      }

      .bs-logo {
        width: 80px;
        height: 80px;
        margin: 20px;
      }

      .bs-button {
        display: flex;
        width: 99px;
        height: 27px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 50px;
        background: #1A3EBD;
        border: none;
        margin: 20px;
        cursor: pointer;
        margin-bottom: 20px;
      }

      .see-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }

      .bt-see {
        display: flex;
        width: 131px;
        height: 27px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 50px;
        background: #1A3EBD;
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 40px 150px 0px 60px;
      }

      /* styles.css */

      .top-up {
        position: fixed;
        bottom: 240px;
        right: 100px;
        background-color: #1A3EBD;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 16px;
        cursor: pointer;
        border-radius: 5px;
        display: none; /* Hide by default */
        transition: opacity 0.3s ease-in-out; /* Smooth transition */
      }

      /* Show the button when isVisible state is true */
      .top-up.show {
        display: block;
      }

      .arrow {
        margin-right: 5px; /* Space between arrow symbol and text */
      }

      /* Hover animation */
      .top-up:hover {
        background-color: #1A3EBD; /* New background color */
        animation: bounce 0.5s ease infinite alternate; /* Bouncing animation */
      }

      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-5px);
        }
      }

      .footer {
        background: #0A112E;
      }

      .footer1 {
        display: flex;
        align-items: flex-start;
        justify-content: baseline;
        flex-direction: column;
      }

      .ft-text-container{
        margin: 300px 100px 40px 100px;
      }

      .footer-img-container {
        display: flex;
        align-items: flex-start;
        justify-content: start;
      }
  
      .footer-logo{
        width: 18.786px;
        height: 24.77px;
        flex-shrink: 0;
        margin-right: 5px;
      }
  
      .footer-logo-name{
        color: var(--white-100, #FFF);
        font-size: 20.654px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.207px;
      }

      .copy-text {
        color: var(--White, var(--white-100, #FFF));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        cursor: pointer;
      }

      .copy-text .underline {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
        cursor: pointer;
      }  

      .footer-text-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px 100px 50px;
      }

      .footer-help {
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-left: 50px;
        cursor: pointer;
      }

      .footer-link-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
}

@media only screen and (max-width: 768px) {

  .search-container{
    background: #0A112E;
    
    .header-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logo-img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
    }

    .logo{
      width: 18.786px;
      height: 24.77px;
      flex-shrink: 0;
      margin-right: 5px;
    }

    .logo-name{
      color: var(--white-100, #FFF);
      font-size: 20.654px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.207px;
    }

    .search {
      display: flex;
      width: 100%;
      height: 20px;
      padding: 10px 20px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 25px;
      border: 0.4px solid var(--white-100, #FFF);
      background: rgba(120, 120, 120, 0.40);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      justify-content: center;
      margin: auto;
    }

    .search-box {
      background: none;
      border: none;
      width: 90%;
      height: 35px;
      box-shadow: none;
      outline: none;
    }
    
    .search-box:focus {
      border: 1px solid transparent;
    }    

    .search-icon-container {
      display: flex;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 50px;
      background: #FB591E;
    }

    .search-icon {
      width: 15.375px;
      height: 15.375px;
      flex-shrink: 0;
      fill: var(--white-100, #FFF);
    }  

    .login{
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: none;
    }

      .button-container {
        display: flex;
        align-items: center;
      }

      .button1{
        color: #FB591E;
        font-size: 16.918px;
        font-style: normal;
        font-weight: 500;
        line-height: 12.478px; /* 114.286% */
        background: none;
        margin-right: 30px;
        box-shadow: none;
        border: none;
        cursor: pointer;
      }

      .button2{
        display: flex;
        padding: 10.416px 11.573px;
        justify-content: center;
        align-items: center;
        gap: 4.629px;
        border-radius: 8.629px;
        background: #FB591E;
        margin-left: 30px;
        color: #FAFAFB;
        font-size: 16.416px;
        font-style: normal;
        font-weight: 700;
        line-height: 13.888px;
        box-shadow: none;
        border: none;
        cursor: pointer;
      }

      .home-text {
        color: var(--white-100, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: none;
        border: none;
        background: none;
        margin-left: auto;
        cursor: pointer;
        margin-right: 50px;
        padding-bottom: 5px;
      }  

      .category-container {
        margin: 10px 25px 10px 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto; /* Set to auto to enable horizontal scrolling */
        max-height: 80px;
        flex-wrap: nowrap;
      }
    
      .category-container::-webkit-scrollbar {
        width: 0; /* Hide scrollbar for WebKit browsers */
      }

      .arrow-bro{
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);
        width: 90%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
      }

      .category-bt1 {
        background: #1735A0;
        display: flex;
        padding: 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        color: #CACACA;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.15px;
        border: none;
        cursor: pointer;
        margin-right: 10px;
      }

      .category-bt2 {
        display: flex;
        padding: 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        background: rgba(159, 159, 161, 0.45);
        color: #CACACA;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.15px;
        border: none;
        cursor: pointer;
        margin-right: 10px;
      }

      .business {
        background: #0A112E;
        height: auto;
      }

      .business::-webkit-scrollbar {
        width: 0.5em;
      }
      
      .business::-webkit-scrollbar-thumb {
        background-color: #0A112E;
        outline: 1px solid #0A112E;
      }

      .business1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }

      .business-text-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px 0px 40px 00px;
      }

      .bs-text-1 {
        color: var(--white-100, #FFF);
        text-shadow: 0px 0.268px 0.489px rgba(0, 0, 0, 0.84);
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }

      .bs-text-2 {
        color: #F4F4F4;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .business-world-container {
        margin: 10px 20px 0px 20px;
      }

      .business-world1-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .no-business{
        padding-left: 30px;
        padding-right: 30px;
        background-color: #0A112E;
        color: var(--white-100, #FFF);
        font-size: 18px;
      }

      .business-logo-container {
        border-radius: 9px;
        border: 1px solid #1A3EBD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin: 0px 15px 30px 15px;
      }

      .bs-name {
        color: #F4F4F4;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1px;
      }

      .bs-logo {
        width: 80px;
        height: 80px;
        margin: 20px;
      }

      .bs-button {
        display: flex;
        width: auto;
        height: auto;
        padding: 5px 30px;
        margin-right: 10px;
        margin-left: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 16px;
        background: #1A3EBD;
        border: none;
        margin: 20px;
        cursor: pointer;
        margin-bottom: 20px;
      }

      .see-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }

      .bt-see {
        display: flex;
        width: 131px;
        height: 27px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 50px;
        background: #1A3EBD;
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 40px 150px 0px 60px;
      }

      .top-up {
        position: fixed;
        bottom: 200px;
        right: 40px;
        background-color: #1A3EBD;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 16px;
        cursor: pointer;
        border-radius: 5px;
        display: none; /* Hide by default */
        transition: opacity 0.3s ease-in-out; /* Smooth transition */
      }

      /* Show the button when isVisible state is true */
      .top-up.show {
        display: block;
      }

      .arrow {
        margin-right: 5px; /* Space between arrow symbol and text */
      }

      /* Hover animation */
      .top-up:hover {
        background-color: #1A3EBD; /* New background color */
        animation: bounce 0.5s ease infinite alternate; /* Bouncing animation */
      }

      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-5px);
        }
      }

      .footer {
        background: #0A112E;
      }

      .footer1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .ft-text-container{
        margin: 300px 10px 40px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .footer-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .footer-logo{
        width: 18.786px;
        height: 24.77px;
        flex-shrink: 0;
        margin-right: 5px;
      }
  
      .footer-logo-name{
        color: var(--white-100, #FFF);
        font-size: 20.654px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.207px;
      }

      .copy-text {
        color: var(--White, var(--white-100, #FFF));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .footer-text-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0px 100px 0px;
      }

      .footer-help {
        color: var(--white-100, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 10px;
      }

      .footer-link-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
}

 }